import { render, staticRenderFns } from "./FarewellMedia.vue?vue&type=template&id=cfbff514&scoped=true&"
import script from "./FarewellMedia.vue?vue&type=script&lang=js&"
export * from "./FarewellMedia.vue?vue&type=script&lang=js&"
import style0 from "./FarewellMedia.vue?vue&type=style&index=0&id=cfbff514&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cfbff514",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VFadeTransition,VRow,VSpacer,VTextField})
