import { render, staticRenderFns } from "./LifeArrangementsLarge.vue?vue&type=template&id=61419c9c&scoped=true&"
import script from "./LifeArrangementsLarge.vue?vue&type=script&lang=js&"
export * from "./LifeArrangementsLarge.vue?vue&type=script&lang=js&"
import style0 from "./LifeArrangementsLarge.vue?vue&type=style&index=0&id=61419c9c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61419c9c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCol,VFadeTransition,VRow})
