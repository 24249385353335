<template>
  <v-icon :color="color" :size="size">{{
    !circular ? `mdi-chevron-${direction}` : `mdi-chevron-${direction}-circle`
  }}</v-icon>
</template>

<script>
export default {
  name: "ChevronIcon",
  props: {
    direction: {
      type: String,
      default: "right",
    },
    color: {
      type: String,
      default: "orange",
    },
    size: {
      type: Number,
      default: 24,
    },
    circular: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
