<template>
  <ul>
    <li
      v-for="(section, index) of sections"
      :key="`sub-nav-link-${index}`"
      @click="
        $router.push({
          path: section.path,
        })
      "
    >
      <v-row no-gutters>
        <v-col cols="9">
          <v-hover v-slot="{ hover }">
            <span :class="{ 'red--text': hover, 'black--text': !hover }">
              {{ section.text }}
            </span>
          </v-hover>
        </v-col>
        <v-col cols="3" class="pl-1">
          <ChevronIcon class="icon_right" color="#999999" />
        </v-col>
      </v-row>
    </li>
  </ul>
</template>

<script>
import ChevronIcon from "@/components/Common/Buttons/ChevronIcon.vue";
import { sections as constants } from "@/constants.js";
import { ref } from "vue";

export default {
  name: "SubMenuTablet",
  components: {
    ChevronIcon,
  },
  setup() {
    const sections = ref(constants);
    return { sections };
  },
};
</script>

<style scoped>
ul {
  list-style: none;
}
.v-icon {
  float: right;
}
li {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;
  cursor: pointer;
  margin: 0 38px 33px 32px;
}
</style>
