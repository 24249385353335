<template>
  <div>
    <v-icon
      v-for="index in completedSteps"
      :key="`${index}-progress-dot-color-complete`"
      size="9"
      class="mr-2"
      :color="color"
      >mdi-circle</v-icon
    >
    <v-icon
      v-for="index in totalSteps - completedSteps"
      :key="`${index}-progress-dot-color-pending`"
      size="9"
      class="mr-2"
      :color="color"
      >mdi-checkbox-blank-circle-outline</v-icon
    >
  </div>
</template>

<script>
export default {
  name: "ProgressDots",
  props: ["completedSteps", "totalSteps", "color"],
};
</script>

<style scoped></style>
