<template>
  <div>
    <v-row no-gutters class="mb-4">
      <v-spacer />
      <BaseButton
        :color="color"
        textColor="white"
        text="+ Add someone"
        @click="showModal = true"
      />
    </v-row>
    <div class="guestList">
      <v-row>
        <v-col cols="3" class="item_name font-weight-bold">Name</v-col>
        <v-col cols="3" class="item_name font-weight-bold">Role</v-col>
        <v-col cols="3" class="item_name font-weight-bold">Note</v-col>
        <v-col cols="3" class="item_name font-weight-bold">Actions</v-col>
      </v-row>
      <v-divider class="mt-2" :color="color"></v-divider>
      <div v-for="item in guestList" :key="`${item.id}-list`">
        <v-row class="my-1 py-1">
          <v-col cols="3" class="item_name text-truncate">{{
            item.name || "-"
          }}</v-col>
          <v-col cols="3" class="item_name text-truncate">{{
            item.role || "-"
          }}</v-col>
          <v-col cols="3" class="item_name text-truncate">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  {{ item.note || "-" }}
                </span>
              </template>
              <span>{{ item.note || "-" }}</span>
            </v-tooltip>
          </v-col>
          <v-col cols="3" class="edit_delete">
            <button
              @click="
                input = item;
                selectedItemToEdit = item.id;
                showModal = true;
              "
            >
              Edit
            </button>
            <span class="mx-1">|</span>
            <button
              @click="guestList = guestList.filter((ele) => ele.id !== item.id)"
            >
              Delete
            </button></v-col
          >
        </v-row>
        <v-divider :color="color"></v-divider>
      </div>
    </div>
    <BaseModal
      :showModal.sync="showModal"
      :showToolBar="false"
      buttonText="Save"
      buttonIcon="mdi-content-save"
      toolbarTextColor="white"
      :toolbarColor="color"
      :btnColor="color"
      @button-click="saveSong"
      @update:showModal="!$event && closeModal()"
      width="600px"
    >
      <template #content>
        <div class="pt-6">
          <v-row no-gutters>
            <v-col
              ><v-text-field
                v-model="input.name"
                :color="color"
                :rules="[() => !!input.name || 'name field is required']"
                single-line
                label="Name"
                class="mx-7 mt-3"
                filled
            /></v-col>
            <v-col
              ><v-text-field
                v-model="input.role"
                :color="color"
                :rules="[() => !!input.role || 'role field is required']"
                single-line
                label="Role"
                class="mx-7 mt-3"
                filled
            /></v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-text-field
                v-model="input.note"
                :color="color"
                single-line
                label="Your note..."
                class="mx-7 mt-3"
                filled
              />
            </v-col>
          </v-row>
        </div>
      </template>
    </BaseModal>
  </div>
</template>

<script>
import { ref } from "vue";
import BaseButton from "@/components/Common/Buttons/BaseButton.vue";
import BaseModal from "@/components/Common/BaseModal.vue";

export default {
  name: "FarewellSpeakers",
  components: { BaseButton, BaseModal },
  props: {
    color: {
      type: String,
      required: false,
      default: "orange",
    },
  },
  setup() {
    const input = ref({});
    const inputUrl = ref("");
    const showModal = ref(false);
    const editInputUrlMode = ref(false);
    const selectedItemToEdit = ref(null);
    const guestList = ref([
      {
        id: 1,
        name: "satish",
        note: "lorem ipsom",
        role: "Lorem a",
        complete: true,
      },
      {
        id: 2,
        name: "john",
        note: "loejrihjb bdwvhvj",
        role: "Lorem b",
        complete: false,
      },
      {
        id: 3,
        name: "kateryn",
        note: "loejrihjb bdwvhvj",
        role: "Lorem c",
        complete: false,
      },
      {
        id: 4,
        name: "charlie",
        note: "loejrihjb bdwvhvj",
        role: "Lorem d",
        complete: false,
      },
      {
        id: 5,
        name: "satish",
        note: "loejrihjb bdwvhvj",
        role: "Lorem e",
        complete: false,
      },
      {
        id: 6,
        name: "noah",
        note: "lorem ipsom",
        role: "Lorem f",
        complete: false,
      },
    ]);
    const saveSong = () => {
      selectedItemToEdit.value !== null
        ? (guestList.value = guestList.value.map((ele) =>
            ele.id === selectedItemToEdit.value ? input.value : ele
          ))
        : guestList.value.push({
            id: guestList.value.length + 1,
            ...input.value,
          });
      selectedItemToEdit.value = null;
      input.value = {};
      showModal.value = false;
    };
    const closeModal = () => {
      showModal.value = false;
      selectedItemToEdit.value = null;
      input.value = {};
    };
    return {
      input,
      editInputUrlMode,
      inputUrl,
      selectedItemToEdit,
      showModal,
      guestList,
      closeModal,
      saveSong,
    };
  },
};
</script>

<style scoped>
.v-btn {
  text-transform: initial !important;
}
.edit_delete {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 22px;
  color: #919396;
}
.item_name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: black;
}
.hover-cursor:hover {
  cursor: pointer;
}
.v-text-field {
  border-radius: 8px;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 22px !important;
}
.guestList {
  overflow: scroll;
  max-height: 40vh;
}
.subText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #000;
}
</style>
