<template>
  <div>
    <div
      v-for="(item, index) in textList"
      :key="`${index}-guide-text`"
      class="pt-2"
    >
      {{ item }}
    </div>
  </div>
</template>

<script>
export default {
  name: "GuideText",
  props: ["textList"],
};
</script>
