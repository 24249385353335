<template>
  <v-expansion-panels flat accordion>
    <v-expansion-panel
      v-for="(item, ind) in items"
      :key="ind"
      @click="selectedItem = selectedItem !== ind ? ind : -1"
    >
      <v-expansion-panel-header
        ><span>{{ item.text }}</span>
        <template #actions>
          <v-icon v-if="selectedItem === ind" :color="color">
            mdi-minus-circle
          </v-icon>
          <v-icon v-else :color="color"> mdi-plus-circle </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <TextArea
          :value="item.value"
          :size="item.value.length < 100 ? 'x-small' : 'medium'"
          @update:value="item.value = $event"
          class="mt-5"
        />
      </v-expansion-panel-content>
      <v-divider :color="color" class="mx-7" />
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { ref } from "vue";
import TextArea from "@/components/Common/TextArea.vue";

export default {
  name: "BaseAccordian",
  components: { TextArea },
  props: {
    color: { type: String, default: "orange" },
    items: {
      type: Array,
      default: () => [
        { text: "Question 1 goes here", value: "" },
        { text: "Question 2 goes here", value: "" },
        { text: "Question 3 goes here", value: "" },
        { text: "Question 4 goes here", value: "" },
      ],
    },
  },
  setup() {
    const selectedItem = ref(-1);
    return {
      selectedItem,
    };
  },
};
</script>

<style scoped>
span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
}
.v-expansion-panels {
  z-index: unset !important;
}
</style>
