<template>
  <ul
    :class="{
      desktop_ul: source === 'desktop',
      mobile_ul: source === 'mobile',
      tablet_ul: source === 'tablet',
    }"
  >
    <li
      v-for="(link, index) of links"
      :key="`nav-link-${index}`"
      :class="{
        mobile_text: source === 'mobile',
        tablet_text: source === 'tablet',
        'red--text': true,
      }"
    >
      <div class="align-self-center" style="min-width: 100%">
        {{ link }}
        <ChevronIcon
          :size="source === 'desktop' ? 36 : 24"
          color="orange"
          :class="{
            'mt-2': source === 'desktop',
            'mt-1': source === 'tablet',
          }"
        />
        <v-divider class="mt-3 mb-2" />
      </div>
    </li>
  </ul>
</template>

<script>
import { ref } from "vue";
import ChevronIcon from "@/components/Common/Buttons/ChevronIcon.vue";

export default {
  name: "NavigationList",
  components: { ChevronIcon },
  props: {
    source: {
      default: "desktop",
      required: false,
    },
  },
  setup() {
    const links = ref([
      "What's this all about then",
      "Who is mortle?",
      "Who cares?",
      "FAQ",
      "Get in touch",
    ]);
    return {
      links,
    };
  },
};
</script>

<style scoped>
li {
  font-family: "Castoro";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 50px;
  cursor: pointer;
}
li:hover {
  color: #ff6e3f !important;
}
ul {
  list-style: none;
}
.desktop_ul {
  margin-top: 57px;
  width: 550px;
}
.mobile_ul {
  width: 100%;
  padding: 0;
}
.tablet_ul {
  margin-top: 57px;
}
.v-divider {
  background-color: #ff6e3f;
}
.mobile_text {
  font-size: 12px;
  line-height: 15px;
}
.v-icon {
  float: right;
}
.tablet_text {
  font-size: 1.05rem;
  line-height: 30px;
}
</style>
