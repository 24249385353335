export const sections = [
  { id: "arrangements", text: "my arrangements", path: "/" },
  { text: "my details", path: "/" },
  { text: "my death mate", path: "/death-mate" },
  { id: "community", text: "community", path: "/community" },
];

export const colorScheme = {
  red: "#741032",
  orange: "#FF6E3F",
  salmon: "#F4D7D8",
  darkSalmon: "#E3C8C9",
  golden: "#DF9E4D",
  black: "#000000",
  white: "#FFFFFF",
  grey_light: "#F0F1F1",
  grey_medium: "#CCCCCC",
  grey_dark: "#939598",
};

export const arrangementDetails = [
  {
    id: "my-farewell",
    title: "my farewell",
    description:
      'By designing our own funerals, we are leaving behind a message that says, "This is who I was, and this is what I stood for."',
    icon: "StarIcon",
    icon_type: "dark",
    icon_color: "golden",
    completedSteps: 0,
    totalSteps: 9,
    color: "golden",
  },
  {
    id: "from-grave",
    title: "from the grave",
    description: "Leave notes for your loved ones.",
    icon: "GraveIcon",
    icon_type: "dark",
    icon_color: "darkSalmon",
    completedSteps: 0,
    totalSteps: 2,
    color: "darkSalmon",
  },
  {
    id: "life-admin",
    title: "life admin",
    description:
      "Important documents, digital legacy and other final instructions.",
    icon: "LockIcon",
    icon_type: "dark",
    icon_color: "red",
    completedSteps: 0,
    totalSteps: 4,
    color: "red",
  },
  {
    id: "life",
    title: "life",
    description:
      "Who are you? What's your story? How do you want to be remembered?",
    icon: "HeartIcon",
    icon_type: "dark",
    icon_color: "orange",
    completedSteps: 0,
    totalSteps: 5,
    color: "orange",
  },
];

export const MyArrangementsChoices = {
  life: [
    {
      id: 1,
      component: "DieFulfilled",
      text: "Die fulfilled",
      guideText: [
        "While Mortle is here to assist you with making choices for your afterlife plan, let's start the journey by considering your life and the legacy you will leave behind.",
      ],
      complete: false,
      classes: "sub_text_margin mt-4",
      question:
        "If you had the chance to look back on your life before you died, what top 3 areas would you have wanted to focus on to 'die fulfilled'?",
    },
    {
      id: 2,
      component: "BucketList",
      text: "Bucket list",
      guideText: [
        "While you are still alive, what are the things on your bucket list?",
      ],
      complete: false,
      classes: "",
      question: "What do you want to get done before you kick it?",
    },
    {
      id: 3,
      component: "RememberMe",
      text: "Remember me",
      guideText: [
        "Think of this as curating your own life's exhibition where people can come and go as they please.",
      ],
      complete: false,
      classes: "sub_text_margin mt-4",
      question: "How would you like to be remembered?",
    },
    {
      id: 4,
      component: "MyEpitaph",
      text: "Epitaph",
      guideText: [
        "Spike Milligan had 'I told you I was  ill' written on his gravestone. The  church felt like it was inappropriate  so his family translated it into  Gaelic.There is always a way.",
      ],
      complete: false,
      classes: "sub_text_margin mt-4",
      question:
        "What would be the most appropriate words written in  memory of your life? (this could appear on your memorial plaque)",
    },
    {
      id: 5,
      component: "MyStory",
      text: "My story",
      guideText: [
        "Your legacy includes those stories of your life and those that came before you. Don't let them be lost. Here's an opportunity to capture your life stories.",
      ],
      complete: false,
      classes: "sub_text_margin mt-4",
      question:
        "Do you have a story to share? Life stories fall into a few categories, click on the below to enter your stories",
    },
  ],
  "from-grave": [
    {
      id: 1,
      component: "GraveMessages",
      text: "Written Messages",
      guideText: [
        "Given it is going to be hard to get  people to listen to you once you  die, now is your chance to leave  some personal notes for your  most precious here. Think of those  especially where you would like to  make peace with. Your deathmate  will be responsible for getting  these messages to the right  people after you die..",
      ],
      classes: "sub_text_margin mt-4",
      complete: false,
      question: "Want to share a message with someone?",
    },
  ],
  "my-farewell": [
    {
      id: 1,
      component: "FarewellToneTheme",
      text: "Tone & Theme",
      guideText: [
        "Your end-of-life gathering doesn't need to be sombre; it can, but not if you don't want it to be.",
        "You may want a festive, celebratory occasion with a light peppering of spirituality. The choice is yours, go on, tell people what they should wear.",
      ],
      classes: "sub_text_margin mt-4",
      complete: false,
      question:
        "Select the tone of tone of your farewell, last hurrah, funeral (chose up to three):",
    },
    {
      id: 2,
      component: "FarewellPriorities",
      text: "Priorities",
      guideText: [
        "Have you ever had to quickly turn around an event, birthday, major presentation or funeral? Help make your afterlife celebration arrangements run smoothly by letting your Deathmate know what is important and should be prioritised.  Oh, and how you're going to foot the bill?",
      ],
      classes: "sub_text_margin mt-4",
      complete: false,
      question:
        "Select & assign your top 5 priorities when considering your farewell",
    },
    {
      id: 3,
      component: "FarewellMusic",
      text: "Music",
      guideText: [
        "Music and performance can  create an atmosphere that truly  reflects the way you want to be  remembered.",
        "Add a note if you want to be specific about when and where the song should be played.",
      ],
      classes: "sub_text_margin mt-4",
      complete: false,
      question: "I  have created a music playlist here:",
    },
    {
      id: 4,
      component: "FarewellMedia",
      text: "Photos & Videos",
      guideText: [
        "There will be so many memories captured throughout your life that you want to remember.  Start an album or these and let your Deathmate know where they are.  A lovely way for you to reflect on your life while you are here and a weight off those organising your afterlife celebration.",
      ],
      classes: "sub_text_margin mt-4",
      complete: false,
      question: "My album of photos and videos is located at?",
    },
    {
      id: 5,
      component: "FarewellSpeakers",
      text: "Speakers & Readings",
      guideText: [
        "Having a role at a loved one's farewell isn't easy, but it is a great honour.",
        "Do you have a favorite poem you want to be read?  Do you want Aunty Jean to belt out Amazing  Grace or your favourite florist to do the flowers?  Let your Deathmate know the people, roles and how to contact them.",
      ],
      classes: "sub_text_margin mt-4",
      complete: false,
      question:
        "Add the people you would like to speak at your farewell and their role",
    },
    {
      id: 6,
      component: "FarewellAfterparty",
      text: "Afterparty",
      guideText: [
        "You know old mates will want to  crack on. Everyone you know  wants to share their stories.",
        "The after-party or wake is a very  important part of their mourning  process. But given it's all about  you, why not inject yourself into  this event!",
      ],
      classes: "sub_text_margin mt-4",
      complete: false,
      question:
        "Where would you like your wake or afterparty to be held? Are you having a committal service or your ashes scattered?",
    },
    {
      id: 7,
      component: "FarewellAnythingElse",
      text: "Anything Else",
      guideText: [
        "This could include any wishes you have with your remains, any connection to religious elders or anything else that you want noted.",
      ],
      classes: "sub_text_margin mt-4",
      complete: false,
      question: "The final words on your farewell?",
    },
  ],
  "life-admin": [
    {
      id: 1,
      component: "SensitiveWill",
      text: "Will & life insurance",
      guideText: [
        "Once the dust settles on your  death, your Deathmate is going to  want to know about your legal stuff and  what you want to do with what.",
        "Of course, someone as organised  as you has a Will. If you haven't  jump in here.",
      ],
      classes: "sub_text_margin mt-4",
      complete: false,
      question: "Where would someone find your Will?",
    },
    {
      id: 2,
      component: "SensitiveInstructions",
      text: "Other Instructions",
      guideText: [
        "Your will can capture a lot, but  often not the more intangible  wishes you may have. You might  want to let your children know  that you really would prefer them  to be wild hippies living off the  land rather than climbing the  corporate ladder; or, you might want to make sure you remind your  partner to get a new car.",
      ],
      classes: "sub_text_margin mt-4",
      complete: false,
      question: "",
    },
    {
      id: 3,
      component: "SensitiveDigitalfootprint",
      text: "Digital footprint",
      guideText: [
        "‘Digital legacy or footprint’ generally describes all our digital property that you leave when you die.  Have you given instructions for the items in the list.",
      ],
      classes: "sub_text_margin mt-4",
      complete: false,
      question: "",
    },
  ],
};

export const dieFulfilledChoices = [
  { text: "Relationships", chosen: false },
  { text: "Learning", chosen: false },
  { text: "Spirituality", chosen: false },
  { text: "Health", chosen: false },
  { text: "Adventure", chosen: false },
  { text: "Vocation", chosen: false },
  { text: "Community", chosen: false },
  { text: "Financial", chosen: true },
  { text: "Cultural", chosen: false },
  { text: "Emotional", chosen: false },
  { text: "Environmental", chosen: false },
  { text: "Values", chosen: false },
  { text: "Purpose", chosen: true },
  { text: "Interests", chosen: false },
  { text: "Ownership", chosen: false },
  { text: "Action", chosen: false },
];

export const toneThemeChoices = {
  themes: [
    { text: "Happy", chosen: false },
    { text: "Sombre", chosen: false },
    { text: "Light", chosen: false },
    { text: "Celebratory", chosen: false },
    { text: "Reflective", chosen: false },
    { text: "Traditional", chosen: false },
    { text: "Upbeat", chosen: false },
    { text: "Spiritual", chosen: true },
    { text: "Religious", chosen: false },
    { text: "Festive", chosen: false },
    { text: "Joyful", chosen: false },
    { text: "Dignified", chosen: false },
    { text: "Casual", chosen: true },
    { text: "Warm", chosen: false },
    { text: "Intimate", chosen: false },
    { text: "Formal", chosen: false },
  ],
  colorPalletes: [],
};

export const farewellPriorityChoices = [
  { text: "Coffin", priority: 2, chosen: true },
  { text: "Urn", priority: 3, chosen: true },
  { text: "Cremation", priority: 0, chosen: true },
  { text: "Burial", priority: 0, chosen: false },
  { text: "Flowers", priority: 0, chosen: false },
  { text: "Afterparty/Wake", priority: 0, chosen: false },
  { text: "Place of worship", priority: 0, chosen: false },
  { text: "Chosen location", priority: 0, chosen: true },
  { text: "Religious ceremony", priority: 0, chosen: false },
  { text: "Ashed to be scattered", priority: 0, chosen: false },
  { text: "Headstone", priority: 0, chosen: false },
  { text: "Graveyard", priority: 0, chosen: false },
  { text: "Family Plot", priority: 0, chosen: true },
  { text: "Plaque in a special place", priority: 0, chosen: false },
  { text: "Keepsake for guests", priority: 0, chosen: false },
  { text: "Money goes to charity/cause", priority: 0, chosen: false },
];

export const storyFilters = [
  "love",
  "people",
  "places",
  "tragedy",
  "adventure",
  "success",
  "legacy",
  "journey",
];
