<template>
  <div>
    <v-text-field
      v-model="input"
      :color="color"
      single-line
      label="Type here"
      filled
    >
      <template v-if="!$vuetify.breakpoint.smAndUp" #append-outer>
        <HelpNotficationPlusMinusIcon
          :color="color"
          type="plus"
          @click="addMessage"
        />
      </template>
    </v-text-field>
    <v-row no-gutters class="mb-4" v-if="$vuetify.breakpoint.smAndUp">
      <v-spacer />
      <BaseButton
        :color="color"
        textColor="white"
        :disabled="!input"
        :text="selectedMessageToEdit === null ? '+ Add Another' : 'Save'"
        @click="addMessage"
      />
    </v-row>
    <div class="messages-list">
      <div v-for="item in messages" :key="`${item.text}-list`">
        <div class="d-flex flex-row justify-space-between">
          <div
            class="align-self-center my-3 hover-cursor text-truncate"
            @click="
              item.complete = !item.complete;
              $emit(
                messages.filter((c) => c.complete).length === messages.length
                  ? 'completed'
                  : 'pending'
              );
            "
          >
            <CheckBox
              class="my-2 mr-3"
              :color="color"
              :complete="item.complete"
            />
            <span class="item_name">
              {{ item.text }}
            </span>
          </div>
          <div class="align-self-center edit_delete">
            <button
              @click="
                selectedMessageToEdit = item;
                input = item.text;
              "
            >
              Edit
            </button>
            <span class="mx-1">|</span>
            <button
              @click="messages = messages.filter((ele) => ele.id !== item.id)"
            >
              Delete
            </button>
            <v-tooltip bottom :color="color">
              <template v-slot:activator="{ on, attrs }">
                <button
                  icon
                  v-on="on"
                  v-bind="attrs"
                  fab
                  @click="showModal = true"
                >
                  <v-icon class="ml-2" :color="color">mdi-share-circle</v-icon>
                </button>
              </template>
              <span>Do you want to share this message with someone?</span>
            </v-tooltip>
          </div>
        </div>
        <v-divider :color="color"></v-divider>
      </div>
    </div>
    <BaseModal
      :showModal.sync="showModal"
      :showToolBar="false"
      buttonText="Share"
      toolbarTextColor="white"
      :toolbarColor="color"
      :btnColor="color"
      width="600px"
    >
      <template #content>
        <div class="pt-6">
          <v-row no-gutters>
            <v-col
              ><v-text-field
                :color="color"
                single-line
                label="Name"
                class="mx-7 mt-3"
                filled
            /></v-col>
            <v-col
              ><v-text-field
                :color="color"
                single-line
                label="Email"
                class="mx-7 mt-3"
                filled
            /></v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-text-field
                :color="color"
                single-line
                label="Your Message..."
                class="mx-7 mt-3"
                filled
              />
            </v-col>
          </v-row>
        </div>
      </template>
    </BaseModal>
  </div>
</template>

<script>
import { ref } from "vue";
import HelpNotficationPlusMinusIcon from "@/components/Common/Icons/HelpNotficationPlusMinusIcon.vue";
import CheckBox from "@/components/Common/Buttons/CheckBox.vue";
import BaseButton from "@/components/Common/Buttons/BaseButton.vue";
import BaseModal from "@/components/Common/BaseModal.vue";

export default {
  name: "GraveMessages",
  emits: ["completed", "pending"],
  props: {
    color: {
      type: String,
      required: false,
      default: "orange",
    },
  },
  components: {
    BaseButton,
    BaseModal,
    HelpNotficationPlusMinusIcon,
    CheckBox,
  },
  setup() {
    const selectedMessageToEdit = ref(null);
    const showModal = ref(false);
    const input = ref("");
    const messages = ref([
      { id: 1, text: "Please look after my dog", complete: true },
      { id: 2, text: "Please look after my cat", complete: false },
      { id: 3, text: "Please look after my plants", complete: false },
    ]);
    const addMessage = () => {
      if (input.value === "") return;
      selectedMessageToEdit.value === null
        ? messages.value.push({
            text: input.value,
            complete: false,
          })
        : (messages.value.find(
            (ele) => ele.id === selectedMessageToEdit.value.id
          ).text = input.value);
      input.value = "";
      selectedMessageToEdit.value = null;
    };
    return {
      input,
      messages,
      showModal,
      addMessage,
      selectedMessageToEdit,
    };
  },
};
</script>

<style scoped>
.messages-list {
  overflow: scroll;
  max-height: 40vh;
}
.edit_delete {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 22px;
  color: #919396;
}
</style>
