<template>
  <footer>
    <div class="d-flex flex-row align-self-center justify-space-between">
      <div @click="$router.push({ path: '/arrangements/life' })">
        <HeartIcon :type="section === 'life' ? 'dark' : 'bw'" />
      </div>
      <div @click="$router.push({ path: '/arrangements/my-farewell' })">
        <StarIcon :type="section === 'my-farewell' ? 'dark' : 'bw'" />
      </div>
      <div @click="$router.push({ path: '/arrangements/from-grave' })">
        <GraveIcon :type="section === 'from-grave' ? 'dark' : 'bw'" />
      </div>
      <div @click="$router.push({ path: '/arrangements/sensitive-stuff' })">
        <LockIcon :type="section === 'sensitive-stuff' ? 'dark' : 'bw'" />
      </div>
    </div>
  </footer>
</template>

<script>
import LockIcon from "@/components/Common/Icons/LockIcon.vue";
import GraveIcon from "@/components/Common/Icons/GraveIcon.vue";
import HeartIcon from "@/components/Common/Icons/HeartIcon.vue";
import StarIcon from "@/components/Common/Icons/StarIcon.vue";
import { useRoute } from "vue2-helpers/vue-router";

export default {
  name: "ArrangementFooter",
  components: { LockIcon, GraveIcon, HeartIcon, StarIcon },
  setup() {
    const route = useRoute();
    return {
      section: route.params.section,
    };
  },
};
</script>

<style scoped>
footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  box-shadow: 2.94px 2.94px 14.72px rgba(0, 0, 0, 0.46);
  background-color: white;
  height: 100px;
  padding: 0 25px;
}
svg {
  margin: 23px 0;
}
</style>
