<template>
  <div>
    <v-card
      :class="{
        'sub_text_margin mt-5 d-flex align-content-space-around flex-wrap mb-3': true,
        'mt-5 mb-5': !$vuetify.breakpoint.smAndUp,
      }"
      elevation="0"
    >
      <v-card class="pr-4 pt-4" width="100" elevation="0">
        <BaseButton
          :color="color"
          :textColor="selectedFilter !== 'All' ? color : 'white'"
          text="All"
          textSize="caption"
          :outlined="selectedFilter !== 'All'"
          :width="95"
          @click="selectedFilter = 'All'"
        /> </v-card
      ><v-card
        v-for="(filter, index) in filters"
        :key="index"
        class="pr-4 pt-4"
        width="100"
        elevation="0"
      >
        <BaseButton
          :color="color"
          :textColor="selectedFilter !== filter ? color : 'white'"
          :text="filter"
          textSize="caption"
          :outlined="selectedFilter !== filter"
          :width="95"
          @click="selectedFilter = filter"
        />
      </v-card>
    </v-card>
    <v-text-field
      v-if="selectedFilter !== 'All'"
      v-model="input"
      :color="color"
      single-line
      label="Type here"
      filled
    >
      <template v-if="!$vuetify.breakpoint.smAndUp" #append-outer>
        <HelpNotficationPlusMinusIcon
          :color="color"
          type="plus"
          @click="addStory"
        />
      </template>
    </v-text-field>
    <v-row
      no-gutters
      class="mb-4"
      v-if="$vuetify.breakpoint.smAndUp && selectedFilter !== 'All'"
    >
      <v-spacer />
      <BaseButton
        :color="color"
        :disabled="!input"
        textColor="white"
        :text="selectedStoryToEdit === null ? '+ Add Another' : 'Save'"
        @click="addStory"
      />
    </v-row>
    <div class="stories-list">
      <div
        v-for="item in stories.filter(
          (story) =>
            selectedFilter === 'All' || story.category === selectedFilter
        )"
        :key="`${item.text}-list`"
      >
        <div class="d-flex flex-row justify-space-between">
          <div
            class="align-self-center my-3 hover-cursor text-truncate"
            @click="
              item.complete = !item.complete;
              $emit(
                stories.filter((c) => c.complete).length === stories.length
                  ? 'completed'
                  : 'pending'
              );
            "
          >
            <CheckBox
              class="my-2 mr-3"
              :color="color"
              :complete="item.complete"
            />
            <span class="item_name">
              {{ item.text }}
            </span>
            <v-chip :color="color" x-small class="ml-3">
              <span class="white--text font-weight-bold">
                {{ item.category }}
              </span>
            </v-chip>
          </div>
          <div class="align-self-center edit_delete">
            <button
              @click="
                selectedStoryToEdit = item;
                input = item.text;
              "
            >
              Edit
            </button>
            <span class="mx-1">|</span>
            <button
              @click="stories = stories.filter((ele) => ele.id !== item.id)"
            >
              Delete
            </button>
            <v-tooltip bottom :color="color">
              <template v-slot:activator="{ on, attrs }">
                <button
                  icon
                  v-on="on"
                  v-bind="attrs"
                  fab
                  @click="showModal = true"
                >
                  <v-icon class="ml-2" :color="color">mdi-share-circle</v-icon>
                </button>
              </template>
              <span>Do you want to share this story with someone?</span>
            </v-tooltip>
          </div>
        </div>
        <v-divider :color="color"></v-divider>
      </div>
      <v-card-text
        v-if="
          selectedFilter !== 'All' &&
          stories.filter((story) => story.category === selectedFilter)
            .length === 0
        "
        >No story available in this category!</v-card-text
      >
    </div>
    <BaseModal
      :showModal.sync="showModal"
      :showToolBar="false"
      buttonText="Share"
      toolbarTextColor="white"
      :toolbarColor="color"
      :btnColor="color"
      width="600px"
    >
      <template #content>
        <div class="pt-6">
          <v-row no-gutters>
            <v-col
              ><v-text-field
                :color="color"
                single-line
                label="Name"
                class="mx-7 mt-3"
                filled
            /></v-col>
            <v-col
              ><v-text-field
                :color="color"
                single-line
                label="Email"
                class="mx-7 mt-3"
                filled
            /></v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-text-field
                :color="color"
                single-line
                label="Your Message..."
                class="mx-7 mt-3"
                filled
              />
            </v-col>
          </v-row>
        </div>
      </template>
    </BaseModal>
  </div>
</template>

<script>
import { storyFilters } from "@/constants";
import { ref } from "vue";
import HelpNotficationPlusMinusIcon from "@/components/Common/Icons/HelpNotficationPlusMinusIcon.vue";
import CheckBox from "@/components/Common/Buttons/CheckBox.vue";
import BaseButton from "@/components/Common/Buttons/BaseButton.vue";
import BaseModal from "@/components/Common/BaseModal.vue";

export default {
  name: "MyStory",
  emits: ["completed", "pending"],
  props: {
    color: {
      type: String,
      required: false,
      default: "orange",
    },
  },
  components: {
    BaseButton,
    BaseModal,
    HelpNotficationPlusMinusIcon,
    CheckBox,
  },
  setup() {
    const filters = ref(storyFilters);
    const selectedStoryToEdit = ref(null);
    const showModal = ref(false);
    const input = ref("");
    const selectedFilter = ref("All");
    const stories = ref([
      { id: 1, text: "Lorem a", category: "people", complete: true },
      { id: 2, text: "Lorem b", category: "people", complete: false },
      { id: 3, text: "Lorem c", category: "people", complete: false },
      { id: 4, text: "Lorem d", category: "places", complete: false },
      { id: 5, text: "Lorem e", category: "people", complete: false },
      { id: 6, text: "Lorem f", category: "people", complete: false },
      { id: 7, text: "Lorem g", category: "legacy", complete: false },
      { id: 8, text: "Lorem h", category: "people", complete: false },
      { id: 10, text: "Lorem k", category: "love", complete: false },
      { id: 11, text: "Lorem l", category: "people", complete: false },
      { id: 13, text: "Lorem n", category: "places", complete: false },
    ]);
    const addStory = () => {
      selectedStoryToEdit.value === null
        ? stories.value.push({
            text: input.value,
            complete: false,
            category: selectedFilter.value,
          })
        : (stories.value.find(
            (ele) => ele.id === selectedStoryToEdit.value.id
          ).text = input.value);
      input.value = "";
      selectedStoryToEdit.value = null;
    };
    return {
      filters,
      input,
      stories,
      showModal,
      addStory,
      selectedStoryToEdit,
      selectedFilter,
    };
  },
};
</script>

<style scoped>
.stories-list {
  overflow: scroll;
  max-height: 40vh;
}
.edit_delete {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 22px;
  color: #919396;
}
</style>
