var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{class:{
    tall_footer: _vm.$vuetify.breakpoint.xs,
    short_footer: _vm.$vuetify.breakpoint.smAndUp,
  }},[_c('img',{class:{
      cactus_desktop: !_vm.$vuetify.breakpoint.xs && !_vm.$vuetify.breakpoint.sm,
      cactus_tablet: _vm.$vuetify.breakpoint.sm,
      cactus_mobile: _vm.$vuetify.breakpoint.xs,
    },attrs:{"src":require("../../assets/Icons/cactus.svg")}}),_c('img',{class:{
      bone_desktop: !_vm.$vuetify.breakpoint.xs && !_vm.$vuetify.breakpoint.sm,
      bone_tablet: _vm.$vuetify.breakpoint.sm,
      bone_mobile: _vm.$vuetify.breakpoint.xs,
    },attrs:{"src":require("../../assets/Icons/bone.svg"),"width":_vm.$vuetify.breakpoint.xs ? '16' : _vm.$vuetify.breakpoint.sm ? '19' : '26',"height":_vm.$vuetify.breakpoint.xs ? '8' : _vm.$vuetify.breakpoint.sm ? '9' : '10'}}),_c('img',{class:{
      cross_bone_desktop: !_vm.$vuetify.breakpoint.xs && !_vm.$vuetify.breakpoint.sm,
      cross_bone_tablet: _vm.$vuetify.breakpoint.sm,
      cross_bone_mobile: _vm.$vuetify.breakpoint.xs,
    },attrs:{"src":require("../../assets/Icons/cross_bone.svg"),"width":_vm.$vuetify.breakpoint.xs ? '26' : _vm.$vuetify.breakpoint.sm ? '30' : '41',"height":_vm.$vuetify.breakpoint.xs ? '20' : _vm.$vuetify.breakpoint.sm ? '25' : '35'}}),(!_vm.$vuetify.breakpoint.xs)?[_c('InstagramIcon',{staticClass:"instagram"}),_c('FacebookIcon',{staticClass:"facebook"}),_c('TwitterIcon',{staticClass:"twitter"})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }