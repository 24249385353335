<template>
  <div>
    <div>
      <v-text-field
        v-model="input"
        :color="color"
        single-line
        label="Type Here..."
        :disabled="!editInputMode"
        filled
      />
      <v-row no-gutters>
        <v-spacer />
        <v-fade-transition>
          <BaseButton
            v-show="editInputMode"
            @click="
              editInputMode = false;
              input = value;
            "
            color="grey_dark"
            textColor="grey_dark"
            text="Cancel"
            :outlined="true"
            class="mr-2"
          />
        </v-fade-transition>
        <BaseButton
          v-if="editInputMode"
          @click="
            editInputMode = false;
            input.length > 0 && $emit('completed');
          "
          :color="color"
          textColor="white"
          text="Save"
        />
        <BaseButton
          v-else
          @click="editInputMode = true"
          :color="color"
          textColor="white"
          text="Edit"
          class="ml-2"
        />
      </v-row>
    </div>
    <div>
      <div class="my-4 subText">Any specific instructions?</div>
      <TextArea
        :value.sync="instructions"
        :disabled="!editInstructionsMode"
        size="small"
      />
      <v-row no-gutters class="mt-3">
        <v-spacer />
        <v-fade-transition>
          <BaseButton
            v-show="editInstructionsMode"
            @click="
              editInstructionsMode = false;
              instructions = value;
            "
            color="grey_dark"
            textColor="grey_dark"
            text="Cancel"
            :outlined="true"
            class="mr-2"
          />
        </v-fade-transition>
        <BaseButton
          v-if="editInstructionsMode"
          @click="
            editInstructionsMode = false;
            instructions.length > 0 && $emit('completed');
          "
          :color="color"
          textColor="white"
          text="Save"
        />
        <BaseButton
          v-else
          @click="editInstructionsMode = true"
          :color="color"
          textColor="white"
          text="Edit"
          class="ml-2"
        />
      </v-row>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import BaseButton from "@/components/Common/Buttons/BaseButton.vue";
import TextArea from "@/components/Common/TextArea.vue";

export default {
  name: "FarewellAfterparty",
  components: {
    BaseButton,
    TextArea,
  },
  props: {
    color: {
      type: String,
      required: false,
      default: "orange",
    },
    value: {
      type: String,
      required: false,
      default: "",
    },
  },
  setup(props) {
    const input = ref(props.value);
    const instructions = ref("");
    const editInputMode = ref(false);
    const editInstructionsMode = ref(false);
    return {
      instructions,
      editInstructionsMode,
      input,
      editInputMode,
    };
  },
};
</script>

<style scoped>
.subText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #000;
}
</style>
