<template>
  <span :class="`${color}--text`">{{ completedSteps }}/{{ totalSteps }}</span>
</template>

<script>
export default {
  name: "ProgressNumber",
  props: ["completedSteps", "totalSteps", "color"],
};
</script>

<style scoped>
span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 60px;
}
</style>
