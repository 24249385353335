<template>
  <footer
    :class="{
      tall_footer: $vuetify.breakpoint.xs,
      short_footer: $vuetify.breakpoint.smAndUp,
    }"
  >
    <img
      :class="{
        cactus_desktop: !$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm,
        cactus_tablet: $vuetify.breakpoint.sm,
        cactus_mobile: $vuetify.breakpoint.xs,
      }"
      src="../../assets/Icons/cactus.svg"
    />
    <img
      :class="{
        bone_desktop: !$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm,
        bone_tablet: $vuetify.breakpoint.sm,
        bone_mobile: $vuetify.breakpoint.xs,
      }"
      src="../../assets/Icons/bone.svg"
      :width="
        $vuetify.breakpoint.xs ? '16' : $vuetify.breakpoint.sm ? '19' : '26'
      "
      :height="
        $vuetify.breakpoint.xs ? '8' : $vuetify.breakpoint.sm ? '9' : '10'
      "
    />
    <img
      :class="{
        cross_bone_desktop: !$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm,
        cross_bone_tablet: $vuetify.breakpoint.sm,
        cross_bone_mobile: $vuetify.breakpoint.xs,
      }"
      src="../../assets/Icons/cross_bone.svg"
      :width="
        $vuetify.breakpoint.xs ? '26' : $vuetify.breakpoint.sm ? '30' : '41'
      "
      :height="
        $vuetify.breakpoint.xs ? '20' : $vuetify.breakpoint.sm ? '25' : '35'
      "
    />
    <template v-if="!$vuetify.breakpoint.xs">
      <InstagramIcon class="instagram" />
      <FacebookIcon class="facebook" />
      <TwitterIcon class="twitter" />
    </template>
  </footer>
</template>

<script>
import InstagramIcon from "@/components/Common/Icons/InstagramIcon.vue";
import FacebookIcon from "@/components/Common/Icons/FacebookIcon.vue";
import TwitterIcon from "@/components/Common/Icons/TwitterIcon.vue";

export default {
  name: "PageFooter",
  components: {
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
  },
};
</script>

<style scoped>
footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: url("../../assets/Backgrounds/footer.svg") no-repeat top 0 center !important;
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-size: 100% 100%;
}
.tall_footer {
  height: 180px;
}
.short_footer {
  height: 120px;
}
.cactus_desktop {
  position: absolute;
  right: 85px;
  top: -40px;
}
.cactus_tablet {
  position: absolute;
  right: 85px;
  top: -27px;
}
.cactus_mobile {
  position: absolute;
  right: 30px;
  top: 0px;
}
.bone_desktop {
  position: absolute;
  right: 45%;
  top: 65%;
}
.bone_tablet {
  position: absolute;
  right: 45%;
  top: 70%;
}
.bone_mobile {
  position: absolute;
  right: 48%;
  top: 70%;
}
.cross_bone_desktop {
  position: absolute;
  right: 40%;
  top: 40%;
}
.cross_bone_tablet {
  position: absolute;
  right: 40%;
  top: 50%;
}
.cross_bone_mobile {
  position: absolute;
  right: 40%;
  top: 50%;
}
.instagram {
  position: absolute;
  top: 55px;
  right: 100px;
  margin: 0 10px;
}
.facebook {
  position: absolute;
  top: 55px;
  right: 65px;
  margin: 0 10px;
}
.twitter {
  position: absolute;
  top: 55px;
  right: 135px;
  margin: 0 10px;
}
</style>
