<template>
  <div class="navigation_div">
    <SubMenuMobile class="sub_menu" />
    <v-row no-gutters>
      <v-col>
        <NavigationList source="mobile" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import NavigationList from "@/components/Common/NavigationList.vue";
import SubMenuMobile from "@/components/SubNavigation/SubMenuMobile.vue";

export default {
  name: "MobileNavigation",
  components: { NavigationList, SubMenuMobile },
};
</script>

<style scoped>
.navigation_div {
  position: absolute;
  top: 109px;
  width: 100%;
  z-index: 1;
  height: 990px;
  padding: 10px 40px;
  background-image: url("../../assets/Backgrounds/navigation.svg") !important;
  background-repeat: no-repeat !important;
  background-position: top -25px center;
  background-size: cover !important;
}
.sub_menu {
  background: #f4d7d8;
  border-radius: 10px;
  border: 0.71px solid #ffffff;
  margin-bottom: 55px;
}
</style>
