var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{staticClass:"mb-5",attrs:{"no-gutters":"","justify":"space-between","align":"center"}},[_c(_vm.icon,{tag:"component",staticClass:"mr-5",attrs:{"width":46,"height":40}}),_c('v-col',{staticClass:"headText"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',[_c('v-fade-transition',[_c('BackButton',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedTab !== 1),expression:"selectedTab !== 1"}],staticClass:"mr-2",attrs:{"color":_vm.color},on:{"click":function($event){_vm.selectedTab =
              _vm.selectedTab === 1
                ? _vm.choices.length
                : (_vm.selectedTab - 1) % (_vm.choices.length + 1)}}})],1),_c('v-fade-transition',[_c('NextButton',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedTab !== _vm.choices.length),expression:"selectedTab !== choices.length"}],attrs:{"color":_vm.color},on:{"click":function($event){_vm.selectedTab =
              _vm.selectedTab === _vm.choices.length
                ? 1
                : (_vm.selectedTab + 1) % (_vm.choices.length + 1)}}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-card',{staticClass:"py-6",attrs:{"flat":"","rounded":"lg","min-height":_vm.$vuetify.breakpoint.smAndUp ? 570 : 450}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('div',{staticClass:"main_text"},[_vm._v(" "+_vm._s(_vm.choices.find((ele) => ele.id === _vm.selectedTab).text)+" ")]):_vm._e(),_c('div',{class:{
            'sub_text sub_text_margin': true,
            'mt-2 mb-5': !_vm.$vuetify.breakpoint.smAndUp,
          }},[_vm._v(" "+_vm._s(_vm.choices.find((ele) => ele.id === _vm.selectedTab).question)+" ")]),_c(_vm.choices.find((ele) => ele.id === _vm.selectedTab).component,{tag:"component",class:_vm.choices.find((ele) => ele.id === _vm.selectedTab).classes,attrs:{"color":_vm.color},on:{"completed":function($event){_vm.choices.find((ele) => ele.id === _vm.selectedTab).complete = true},"pending":function($event){_vm.choices.find((ele) => ele.id === _vm.selectedTab).complete = false}}})],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('TabSelection',{attrs:{"selectedTab":_vm.selectedTab,"color":_vm.color,"size":"large","choices":_vm.choices},on:{"updateTab":function($event){_vm.selectedTab = $event}}}),_c('v-card',{staticClass:"pa-5 pb-10 mb-5",attrs:{"flat":"","rounded":"lg","min-height":"150"}},[_c('GuideText',{attrs:{"textList":_vm.choices.find((ele) => ele.id === _vm.selectedTab).guideText}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }