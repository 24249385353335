<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 50 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.7901 43.4C28.5201 43.42 29.2601 43.43 29.9801 43.44C35.6901 43.53 40.2501 40.92 44.3901 37.03C49.2401 32.46 51.0402 25.08 49.3402 18.63C48.4002 15.07 49.6901 10.52 47.2301 7.54997C45.5701 5.54997 43.0801 4.43 40.6301 3.58C34.0201 1.28 25.4701 -1.00001 18.9801 2.19999C12.6501 5.31999 7.18013 9.19 3.62013 15.46C2.08013 18.17 0.10013 21.38 0.37013 24.55C0.70013 28.49 3.60013 29.99 6.00013 32.6C7.62013 34.36 8.65014 36.43 10.4401 38.08C15.2101 42.45 21.5801 43.23 27.7901 43.4V43.4Z"
      :fill="colorScheme[outer_color]"
    />
    <path
      d="M36.0401 18.16H18.8401C17.8902 18.16 17.1201 18.9301 17.1201 19.88V32.78C17.1201 33.73 17.8902 34.5 18.8401 34.5H36.0401C36.99 34.5 37.7601 33.73 37.7601 32.78V19.88C37.7601 18.9301 36.99 18.16 36.0401 18.16Z"
      :fill="colorScheme[inner_color]"
    />
    <path
      d="M21.5101 19.9V16.46C21.5101 13.14 24.2102 10.44 27.5302 10.44C30.8502 10.44 33.5501 13.14 33.5501 16.46V19.46"
      :stroke="colorScheme[inner_color]"
      stroke-width="1.72"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M28.8001 24.86C28.8001 24.11 28.1901 23.5 27.4401 23.5C26.6901 23.5 26.0801 24.11 26.0801 24.86C26.0801 25.43 26.4401 25.92 26.9401 26.12V29.16H27.9401V26.12C28.4401 25.92 28.8001 25.43 28.8001 24.86ZM27.4401 24.5C27.6401 24.5 27.8001 24.66 27.8001 24.86C27.8001 25.06 27.6401 25.22 27.4401 25.22C27.2401 25.22 27.0801 25.06 27.0801 24.86C27.0801 24.66 27.2401 24.5 27.4401 24.5Z"
      :fill="colorScheme[outer_color]"
    />
  </svg>
</template>

<script>
import { colorScheme } from "@/constants";

export default {
  name: "LockIcon",
  props: {
    height: {
      type: Number,
      default: 47,
    },
    width: {
      type: Number,
      default: 43,
    },
    color: {
      type: String,
      default: "red",
    },
    type: {
      type: String,
      default: "dark",
    },
  },
  setup(props) {
    let outer_color = props.color,
      inner_color = "white";
    if (props.type === "light") {
      inner_color = props.color;
      outer_color = "white";
    } else if (props.type === "bw") {
      outer_color = "grey_dark";
    }
    return { colorScheme, outer_color, inner_color };
  },
};
</script>
