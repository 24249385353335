<template>
  <v-row class="row_spacing">
    <v-col
      v-for="(section, index) of sections"
      :key="`sub-nav-link-${index}`"
      :cols="$vuetify.breakpoint.width < 300 ? 12 : 6"
      @click="
        $router.push({
          path: section.path,
        })
      "
    >
      <v-row no-gutters>
        <v-col cols="9">
          <v-hover v-slot="{ hover }">
            <span :class="{ 'red--text': hover, 'black--text': !hover }">
              {{ section.text }}
            </span>
          </v-hover>
        </v-col>
        <v-col cols="3" class="pl-1">
          <ChevronIcon :size="16" class="my-2" color="#999999" />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { sections as constants } from "@/constants.js";
import ChevronIcon from "@/components/Common/Buttons/ChevronIcon.vue";
import { ref } from "vue";

export default {
  name: "SubMenuMobile",
  components: {
    ChevronIcon,
  },
  setup() {
    const sections = ref(constants);
    return { sections };
  },
};
</script>

<style scoped>
.v-icon {
  float: right;
}
span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;
}
.row_spacing {
  padding: 34px 15px 34px 22px;
  cursor: pointer;
}
</style>
