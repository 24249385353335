<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 49 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M46.7101 27.23C47.4701 26.02 48.1701 24.73 48.4501 23.33C48.7101 22.05 48.6101 20.72 48.3801 19.43C47.6101 15.14 45.3101 11.2 42.1901 8.15C39.0801 5.1 35.1701 2.91999 31.0501 1.51999C28.6101 0.689995 26.0401 0.130015 23.4701 0.290015C19.2801 0.550015 15.4501 2.68002 11.9601 5.01002C7.16013 8.20002 2.42011 12.16 0.630113 17.64C0.160113 19.08 -0.0898818 20.63 0.260118 22.1C0.620118 23.6 1.58012 24.89 2.57012 26.07C5.54012 29.59 9.09009 32.61 13.0401 34.98C16.0701 36.8 19.3201 38.22 22.5601 39.64C24.6901 40.57 26.9001 41.52 29.2201 41.51C30.6301 41.51 32.0101 41.13 33.3201 40.62C33.9901 40.36 34.6401 40.05 35.2801 39.74C35.7901 39.49 36.3801 39.27 36.8001 38.87C37.9801 37.75 39.1001 36.55 40.2101 35.36C41.3201 34.17 42.4301 32.94 43.4801 31.67C44.5201 30.4 45.5001 29.09 46.3901 27.72C46.4901 27.56 46.5901 27.4 46.6901 27.24L46.7101 27.23Z"
      :fill="colorScheme[outer_color]"
    />
    <path
      d="M29.9201 14.31C30.2101 14.41 30.5002 14.48 30.8302 14.46C31.4202 14.42 32.0401 14.59 32.6201 14.68L34.8101 15C36.1901 15.2 36.7501 16.9 35.7501 17.88L32.2201 21.33C31.8201 21.72 31.6401 22.28 31.7401 22.82L32.5802 27.68C32.8202 29.06 31.3701 30.11 30.1301 29.46L25.7602 27.17C25.2702 26.91 24.6801 26.91 24.1901 27.17L19.8302 29.47C18.5902 30.12 17.1401 29.07 17.3801 27.69L18.2102 22.83C18.3002 22.28 18.1201 21.72 17.7201 21.34L14.1901 17.9C13.1901 16.92 13.7401 15.22 15.1201 15.02C15.8501 14.91 16.5701 14.81 17.2901 14.7C18.1901 14.57 19.1001 14.44 20.0001 14.3C20.5101 14.23 20.8801 13.8 21.1701 13.41C21.5001 12.96 21.7201 12.45 21.9101 11.93C22.2001 11.12 22.4401 10.26 22.9701 9.56999C23.5001 8.87999 24.4101 8.39998 25.2301 8.68998C25.9501 8.93998 26.3801 9.67998 26.6101 10.41C26.8401 11.14 26.9601 11.91 27.3501 12.56C27.8601 13.39 28.7401 13.87 29.6201 14.21C29.7201 14.25 29.8301 14.29 29.9301 14.32L29.9201 14.31Z"
      :fill="colorScheme[inner_color]"
    />
  </svg>
</template>

<script>
import { colorScheme } from "@/constants";

export default {
  name: "StarIcon",
  props: {
    height: {
      type: Number,
      default: 47,
    },
    width: {
      type: Number,
      default: 43,
    },
    color: {
      type: String,
      default: "golden",
    },
    type: {
      type: String,
      default: "dark",
    },
  },
  setup(props) {
    let outer_color = props.color,
      inner_color = "white";
    if (props.type === "light") {
      inner_color = props.color;
      outer_color = "white";
    } else if (props.type === "bw") {
      outer_color = "grey_dark";
    }
    return { colorScheme, outer_color, inner_color };
  },
};
</script>
