import MyArrangements from "@/Views/Arrangements/MyArrangements.vue";
import LifeArrangements from "@/Views/Arrangements/LifeArrangements.vue";
import CommunityHome from "@/Views/Community/index.vue";
import FAQHome from "@/Views/FAQ/index.vue";
import DeathMate from "@/Views/DeathMate/index.vue";
import Router from "vue-router";
import Vue from "vue";

Vue.use(Router);

const routes = [
  {
    path: "/",
    name: "Home",
    component: MyArrangements,
    meta: {
      footer: "PageFooter",
      largeSpace: true,
    },
  },
  {
    path: "/arrangements",
    name: "MyArrangements",
    component: MyArrangements,
    meta: {
      footer: "PageFooter",
      largeSpace: true,
    },
  },
  {
    path: "/arrangements/:section",
    name: "LifeArrangements",
    component: LifeArrangements,
    meta: {
      footer: "ArrangementFooter",
      largeSpace: false,
    },
  },
  {
    path: "/community",
    name: "Community",
    component: CommunityHome,
    meta: {
      footer: "PageFooter",
      largeSpace: true,
    },
  },
  {
    path: "/faq",
    name: "FAQ",
    component: FAQHome,
    meta: {
      footer: "PageFooter",
      largeSpace: true,
    },
  },
  {
    path: "/death-mate",
    name: "DeathMate",
    component: DeathMate,
    meta: {
      footer: "PageFooter",
      largeSpace: true,
    },
  },
];

const router = new Router({
  routes,
  mode: "history",
});

export default router;
