var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{class:{
    desktop_ul: _vm.source === 'desktop',
    mobile_ul: _vm.source === 'mobile',
    tablet_ul: _vm.source === 'tablet',
  }},_vm._l((_vm.links),function(link,index){return _c('li',{key:`nav-link-${index}`,class:{
      mobile_text: _vm.source === 'mobile',
      tablet_text: _vm.source === 'tablet',
      'red--text': true,
    }},[_c('div',{staticClass:"align-self-center",staticStyle:{"min-width":"100%"}},[_vm._v(" "+_vm._s(link)+" "),_c('ChevronIcon',{class:{
          'mt-2': _vm.source === 'desktop',
          'mt-1': _vm.source === 'tablet',
        },attrs:{"size":_vm.source === 'desktop' ? 36 : 24,"color":"orange"}}),_c('v-divider',{staticClass:"mt-3 mb-2"})],1)])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }