<template>
  <v-icon
    v-if="type === 'plus'"
    :size="$vuetify.breakpoint.xs ? 28 : 35"
    :color="color"
    @click="$emit('click')"
    >mdi-plus-circle</v-icon
  >
  <v-icon
    v-else-if="type === 'help'"
    size="35"
    :color="color"
    @click="$emit('click')"
    >mdi-help-circle</v-icon
  >
  <v-btn
    v-else-if="type === 'exclamation'"
    :color="color"
    fab
    depressed
    x-small
    @click="$emit('click')"
    ><v-icon size="25" color="white">mdi-exclamation-thick</v-icon></v-btn
  >
  <v-icon v-else @click="$emit('click')" size="35" :color="color"
    >mdi-minus-circle</v-icon
  >
</template>

<script>
export default {
  name: "HelpNotficationPlusMinusIcon",
  props: {
    color: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: "plus",
    },
  },
};
</script>
