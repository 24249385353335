<template>
  <v-card
    :class="{
      'mt-5 d-flex align-content-space-around flex-wrap': true,
      'mt-5 mb-5': !$vuetify.breakpoint.smAndUp,
    }"
    elevation="0"
  >
    <v-card
      v-for="(choice, index) in choices"
      :key="index"
      class="pr-4 pt-4"
      width="160"
      elevation="0"
    >
      <BaseButton
        :color="color"
        :textColor="!choice.chosen ? color : 'white'"
        :text="choice.text"
        :outlined="!choice.chosen"
        :width="150"
        @click="choose(index)"
      />
    </v-card>
    <v-snackbar v-model="showSnackBar" :color="color" rounded="pill" outlined>
      {{ snackBarText }}
      <v-spacer></v-spacer>
      <template v-slot:action="{ attrs }">
        <v-btn :color="color" v-bind="attrs" text @click="showSnackBar = false">
          Ok
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import { dieFulfilledChoices } from "@/constants";
import { ref } from "vue";
import BaseButton from "@/components/Common/Buttons/BaseButton.vue";

export default {
  name: "DieFulfilled",
  emits: ["completed", "pending"],
  props: {
    color: {
      type: String,
      required: false,
      default: "orange",
    },
  },
  components: {
    BaseButton,
  },
  setup(_, { emit }) {
    const choices = ref(dieFulfilledChoices);
    const showSnackBar = ref(false);
    const snackBarText = ref("");
    const choose = (index) => {
      if (choices.value[index].chosen) {
        choices.value[index].chosen = false;
        emit("pending");
        return;
      }
      if (choices.value.filter((choice) => choice.chosen).length >= 3) {
        snackBarText.value = "You can only choose 3 options";
        showSnackBar.value = true;
        emit("completed");
        return;
      }
      choices.value[index].chosen = true;
      if (choices.value.filter((choice) => choice.chosen).length == 3)
        emit("completed");
    };
    return {
      snackBarText,
      showSnackBar,
      choose,
      choices,
    };
  },
};
</script>
