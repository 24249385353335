<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.04 30.38C23.3243 30.38 30.04 23.6643 30.04 15.38C30.04 7.09573 23.3243 0.380005 15.04 0.380005C6.75577 0.380005 0.0400391 7.09573 0.0400391 15.38C0.0400391 23.6643 6.75577 30.38 15.04 30.38Z"
      :fill="colorScheme[color]"
    />
    <path
      d="M23.0401 10.87C22.7001 11.02 22.3501 11.14 21.9901 11.23C22.3901 10.85 22.6901 10.38 22.8601 9.84998C22.9101 9.69998 22.7401 9.57002 22.6101 9.65002C22.1101 9.95002 21.5601 10.17 21.0001 10.32C20.9701 10.32 20.9301 10.33 20.9001 10.33C20.8001 10.33 20.7001 10.29 20.6201 10.22C20.0201 9.68997 19.2401 9.40002 18.4401 9.40002C18.0901 9.40002 17.7401 9.45 17.4001 9.56C16.3301 9.89 15.5101 10.77 15.2501 11.86C15.1501 12.27 15.1301 12.68 15.1701 13.07C15.1701 13.12 15.1501 13.15 15.1401 13.16C15.1201 13.19 15.0801 13.2 15.0501 13.2H15.0401C12.7001 12.98 10.5901 11.85 9.10007 10.02C9.02007 9.93002 8.8801 9.93998 8.8201 10.04C8.5301 10.54 8.37008 11.11 8.37008 11.7C8.37008 12.59 8.73007 13.44 9.35007 14.05C9.09007 13.99 8.83007 13.89 8.60007 13.76C8.49007 13.7 8.35012 13.78 8.34012 13.91C8.32012 15.24 9.10011 16.42 10.2701 16.95C10.2501 16.95 10.2201 16.95 10.2001 16.95C10.0201 16.95 9.8301 16.93 9.6401 16.9C9.5101 16.88 9.40009 17 9.44009 17.12C9.82009 18.3 10.8301 19.17 12.0501 19.37C11.0401 20.05 9.8701 20.41 8.6401 20.41H8.2601C8.1401 20.41 8.0401 20.49 8.0101 20.6C7.9801 20.71 8.0301 20.83 8.1401 20.89C9.5201 21.69 11.0901 22.12 12.6901 22.12C14.0901 22.12 15.4001 21.84 16.5801 21.3C17.6601 20.8 18.6201 20.08 19.4201 19.17C20.1701 18.32 20.7601 17.34 21.1601 16.25C21.5501 15.22 21.7501 14.11 21.7501 13.06V13.01C21.7501 12.84 21.8301 12.68 21.9601 12.57C22.4701 12.16 22.9101 11.67 23.2701 11.13C23.3701 10.99 23.2101 10.81 23.0601 10.88L23.0401 10.87Z"
      :fill="colorScheme['white']"
    />
  </svg>
</template>

<script>
import { colorScheme } from "@/constants";

export default {
  name: "TwitterIcon",
  props: {
    height: {
      type: Number,
      default: 31,
    },
    width: {
      type: Number,
      default: 31,
    },
    color: {
      type: String,
      default: "red",
    },
  },
  setup() {
    return { colorScheme };
  },
};
</script>
