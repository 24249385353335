<template>
  <div>
    <v-text-field
      v-model="input"
      :color="color"
      single-line
      label="Type here"
      class="mx-7 mt-3"
      filled
    >
      <template v-if="!$vuetify.breakpoint.smAndUp" #append-outer>
        <HelpNotficationPlusMinusIcon
          :color="color"
          type="plus"
          @click="addItem"
        />
      </template>
    </v-text-field>
    <v-row no-gutters class="mx-8" v-if="$vuetify.breakpoint.smAndUp">
      <v-spacer />
      <BaseButton
        :color="color"
        :disabled="!input"
        textColor="white"
        :text="selectedItemToEdit === null ? '+ Add Another' : 'Save'"
        @click="addItem"
      />
    </v-row>
    <div class="itemList">
      <div v-for="item in itemList" :key="`${item.text}-list`" class="mx-8">
        <div class="d-flex flex-row justify-space-between">
          <div
            class="align-self-center my-3 hover-cursor"
            @click="
              item.complete = !item.complete;
              $emit(
                itemList.filter((c) => c.complete).length === itemList.length
                  ? 'completed'
                  : 'pending'
              );
            "
          >
            <CheckBox
              class="my-2 mr-3"
              :color="color"
              :complete="item.complete"
            />
            <span class="item_name">{{ item.text }}</span>
          </div>
          <div class="align-self-center edit_delete">
            <button
              @click="
                selectedItemToEdit = item;
                input = item.text;
              "
            >
              Edit
            </button>
            <span class="mx-1">|</span>
            <button
              @click="itemList = itemList.filter((ele) => ele.id !== item.id)"
            >
              Delete
            </button>
          </div>
        </div>
        <v-divider :color="color"></v-divider>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import HelpNotficationPlusMinusIcon from "@/components/Common/Icons/HelpNotficationPlusMinusIcon.vue";
import CheckBox from "@/components/Common/Buttons/CheckBox.vue";
import BaseButton from "@/components/Common/Buttons/BaseButton.vue";

export default {
  name: "BucketList",
  components: { BaseButton, CheckBox, HelpNotficationPlusMinusIcon },
  props: {
    color: {
      type: String,
      required: false,
      default: "orange",
    },
  },
  emits: ["completed", "pending"],
  setup() {
    const input = ref("");
    const selectedItemToEdit = ref(null);
    const itemList = ref([
      { id: 1, text: "Lorem a", complete: true },
      { id: 2, text: "Lorem b", complete: false },
      { id: 3, text: "Lorem c", complete: false },
      { id: 4, text: "Lorem d", complete: false },
      { id: 5, text: "Lorem e", complete: false },
      { id: 6, text: "Lorem f", complete: false },
      { id: 7, text: "Lorem g", complete: false },
      { id: 8, text: "Lorem h", complete: false },
      { id: 9, text: "Lorem j", complete: false },
      { id: 10, text: "Lorem k", complete: false },
      { id: 11, text: "Lorem l", complete: false },
      { id: 12, text: "Lorem m", complete: false },
      { id: 13, text: "Lorem n", complete: false },
    ]);
    const addItem = () => {
      selectedItemToEdit.value === null
        ? itemList.value.push({ text: input.value, complete: false })
        : (itemList.value.find(
            (ele) => ele.id === selectedItemToEdit.value.id
          ).text = input.value);
      input.value = "";
    };
    return {
      input,
      selectedItemToEdit,
      addItem,
      itemList,
    };
  },
};
</script>

<style scoped>
.v-btn {
  text-transform: initial !important;
}
.edit_delete {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 22px;
  color: #919396;
}
.item_name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 22px;
  color: black;
}
.hover-cursor:hover {
  cursor: pointer;
}
.v-text-field {
  border-radius: 8px;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 22px !important;
}
.itemList {
  overflow: scroll;
  max-height: 40vh;
}
</style>
