var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"min-height":"197","rounded":"xl","color":_vm.state === 'active' ? _vm.color : 'white',"flat":""},on:{"click":function($event){!!_vm.id &&
      _vm.$router.push({
        path: `/arrangements/${_vm.id}/`,
      })}}},[_c('div',{staticClass:"d-flex flex-row align-self-center justify-space-between"},[_c(_vm.icon,{tag:"component",staticClass:"ml-8 mt-4",attrs:{"type":_vm.state === 'active' ? 'light' : 'dark',"width":46,"height":40}}),_c('div',{staticClass:"mr-8 pt-2"},[(_vm.$vuetify.breakpoint.xs)?_c('ProgressNumber',{attrs:{"totalSteps":_vm.totalSteps,"completedSteps":_vm.completedSteps,"color":_vm.state !== 'active' ? _vm.color : 'white'}}):_vm._e()],1)],1),_c('div',{class:{
      'mt-7': !_vm.$vuetify.breakpoint.xs,
      'mt-5': _vm.$vuetify.breakpoint.xs,
      'ml-7 text_title': true,
      'black--text': _vm.state !== 'active',
      'white--text': _vm.state === 'active',
    }},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{class:{
      'mt-3': !_vm.$vuetify.breakpoint.xs,
      'mt-1': _vm.$vuetify.breakpoint.xs,
      'mr-7 ml-7 text_description': true,
      'black--text': _vm.state !== 'active',
      'white--text': _vm.state === 'active',
    }},[_vm._v(" "+_vm._s(_vm.description)+" ")]),_c('ProgressDots',{class:{ 'ml-7 my-3': true, 'mb-8 mt-2': _vm.$vuetify.breakpoint.xs },attrs:{"totalSteps":_vm.totalSteps,"completedSteps":_vm.completedSteps,"color":_vm.state !== 'active' ? _vm.color : 'white'}}),(!_vm.$vuetify.breakpoint.xs)?_c('div',{staticClass:"ml-7 mb-7 d-flex flex-row align-self-center justify-space-between"},[_c('ProgressNumber',{attrs:{"totalSteps":_vm.totalSteps,"completedSteps":_vm.completedSteps,"color":_vm.state !== 'active' ? _vm.color : 'white'}}),_c('v-spacer'),_c('ChevronIcon',{staticClass:"mr-9",attrs:{"size":36,"color":_vm.state === 'active' ? 'white' : _vm.color}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }