<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 47 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.7301 0.579925C17.8401 0.609925 11.6401 1.47994 6.97012 5.05994C1.80012 9.01994 -0.679897 16.02 0.330103 22.45C1.3401 28.88 5.61013 34.6099 11.2301 37.8999C16.8501 41.1899 23.6901 42.1499 30.1101 41.0799C34.5001 40.3499 38.9301 38.5699 41.7401 35.1099C44.6901 31.4899 45.4501 26.5999 45.8301 21.9399C46.1501 17.8999 46.2601 13.8399 46.1601 9.78995C46.1201 8.34995 46.0501 6.86994 45.4401 5.55994C44.3101 3.13994 41.7601 2.65995 39.4201 2.16995C36.1501 1.47995 32.8401 1.00993 29.5001 0.769928C27.5801 0.629928 25.6601 0.569925 23.7401 0.579925H23.7301Z"
      :fill="colorScheme[outer_color]"
    />
    <path
      d="M23.7303 30.3099C23.6003 30.3099 23.4803 30.2999 23.3703 30.2399C23.0103 30.0599 22.6703 29.7299 22.3503 29.4899C19.1703 27.0799 15.9902 24.4199 13.7702 21.0799V21.0599C13.2602 20.0599 12.6603 18.8499 13.0603 17.7399C13.5203 16.4899 13.9502 15.2599 14.7402 14.1699C15.5502 13.0599 16.7003 12.1599 18.0403 11.8599C19.3903 11.5599 20.8503 11.9599 21.9203 12.8099C22.4003 13.1899 22.8303 13.9499 23.5003 13.9999C23.8903 14.0299 24.1403 13.7999 24.4303 13.5699C26.1303 12.2099 28.6403 11.9999 30.6803 12.6899C31.1003 12.8299 31.5003 13.0099 31.8503 13.2699C33.1203 14.2299 33.3703 16.0199 33.3503 17.6199C33.3303 20.0099 32.9103 22.5199 31.4103 24.3699C30.5703 25.3999 29.4603 26.1599 28.4003 26.9499C27.4703 27.6499 26.6903 28.6199 25.6803 29.1899C25.1603 29.4799 24.3602 30.2599 23.7102 30.2999L23.7303 30.3099Z"
      :fill="colorScheme[inner_color]"
    />
  </svg>
</template>

<script>
import { colorScheme } from "@/constants";

export default {
  name: "HeartIcon",
  props: {
    height: {
      type: Number,
      default: 47,
    },
    width: {
      type: Number,
      default: 43,
    },
    color: {
      type: String,
      default: "orange",
    },
    type: {
      type: String,
      default: "dark",
    },
  },
  setup(props) {
    let outer_color = props.color,
      inner_color = "white";
    if (props.type === "light") {
      inner_color = props.color;
      outer_color = "white";
    } else if (props.type === "bw") {
      outer_color = "grey_dark";
    }
    return { colorScheme, outer_color, inner_color };
  },
};
</script>
