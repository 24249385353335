var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-text-field',{attrs:{"color":_vm.color,"single-line":"","label":"Type here","filled":""},scopedSlots:_vm._u([(!_vm.$vuetify.breakpoint.smAndUp)?{key:"append-outer",fn:function(){return [_c('HelpNotficationPlusMinusIcon',{attrs:{"color":_vm.color,"type":"plus"},on:{"click":_vm.addMessage}})]},proxy:true}:null],null,true),model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}}),(_vm.$vuetify.breakpoint.smAndUp)?_c('v-row',{staticClass:"mb-4",attrs:{"no-gutters":""}},[_c('v-spacer'),_c('BaseButton',{attrs:{"color":_vm.color,"textColor":"white","disabled":!_vm.input,"text":_vm.selectedMessageToEdit === null ? '+ Add Another' : 'Save'},on:{"click":_vm.addMessage}})],1):_vm._e(),_c('div',{staticClass:"messages-list"},_vm._l((_vm.messages),function(item){return _c('div',{key:`${item.text}-list`},[_c('div',{staticClass:"d-flex flex-row justify-space-between"},[_c('div',{staticClass:"align-self-center my-3 hover-cursor text-truncate",on:{"click":function($event){item.complete = !item.complete;
            _vm.$emit(
              _vm.messages.filter((c) => c.complete).length === _vm.messages.length
                ? 'completed'
                : 'pending'
            );}}},[_c('CheckBox',{staticClass:"my-2 mr-3",attrs:{"color":_vm.color,"complete":item.complete}}),_c('span',{staticClass:"item_name"},[_vm._v(" "+_vm._s(item.text)+" ")])],1),_c('div',{staticClass:"align-self-center edit_delete"},[_c('button',{on:{"click":function($event){_vm.selectedMessageToEdit = item;
              _vm.input = item.text;}}},[_vm._v(" Edit ")]),_c('span',{staticClass:"mx-1"},[_vm._v("|")]),_c('button',{on:{"click":function($event){_vm.messages = _vm.messages.filter((ele) => ele.id !== item.id)}}},[_vm._v(" Delete ")]),_c('v-tooltip',{attrs:{"bottom":"","color":_vm.color},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('button',_vm._g(_vm._b({attrs:{"icon":"","fab":""},on:{"click":function($event){_vm.showModal = true}}},'button',attrs,false),on),[_c('v-icon',{staticClass:"ml-2",attrs:{"color":_vm.color}},[_vm._v("mdi-share-circle")])],1)]}}],null,true)},[_c('span',[_vm._v("Do you want to share this message with someone?")])])],1)]),_c('v-divider',{attrs:{"color":_vm.color}})],1)}),0),_c('BaseModal',{attrs:{"showModal":_vm.showModal,"showToolBar":false,"buttonText":"Share","toolbarTextColor":"white","toolbarColor":_vm.color,"btnColor":_vm.color,"width":"600px"},on:{"update:showModal":function($event){_vm.showModal=$event},"update:show-modal":function($event){_vm.showModal=$event}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"pt-6"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-text-field',{staticClass:"mx-7 mt-3",attrs:{"color":_vm.color,"single-line":"","label":"Name","filled":""}})],1),_c('v-col',[_c('v-text-field',{staticClass:"mx-7 mt-3",attrs:{"color":_vm.color,"single-line":"","label":"Email","filled":""}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-text-field',{staticClass:"mx-7 mt-3",attrs:{"color":_vm.color,"single-line":"","label":"Your Message...","filled":""}})],1)],1)],1)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }