import { render, staticRenderFns } from "./TheSlider.vue?vue&type=template&id=6152a850&"
import script from "./TheSlider.vue?vue&type=script&lang=js&"
export * from "./TheSlider.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VSlider,VTextField})
