<template>
  <div>
    <v-app-bar
      app
      height="110"
      elevation="0"
      :color="showNavigation ? '#FFFFFF' : color"
    >
      <NavigationButton
        :showNavigation="showNavigation"
        @toggleNavigation="toggleNavigation"
        class="ml-7"
      />
      <v-spacer></v-spacer>
      <span :class="`user_name_mobile ${textColor}--text`"
        >G'day {{ userName }}</span
      >
      <InitialsLogo class="mr-5" :barColor="barColor" :mColor="mColor" />
    </v-app-bar>
    <MobileNavigation v-if="showNavigation" />
  </div>
</template>

<script>
import MobileNavigation from "@/components/NavigationModals/MobileNavigation.vue";
import HeaderMixin from "@/Mixins/HeaderMixin.vue";
import InitialsLogo from "@/components/Common/Logos/InitialsLogo.vue";
import NavigationButton from "@/components/Common/Buttons/NavigationButton.vue";

export default {
  name: "TabletHeader",
  components: { MobileNavigation, NavigationButton, InitialsLogo },
  props: {
    color: {
      type: String,
      default: "#F9EBEB",
      required: false,
    },
    textColor: {
      type: String,
      default: "black",
      required: false,
    },
    mColor: {
      type: String,
      default: "red",
      required: false,
    },
    barColor: {
      type: String,
      default: "orange",
      required: false,
    },
  },
  mixins: [HeaderMixin],
};
</script>

<style scoped>
.user_name_mobile {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: right;
  margin-right: 10px;
}
</style>
