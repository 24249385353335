<script>
import NotificationIcon from "@/components/Common/Icons/NotificationIcon.vue";
import { ref } from "vue";

export default {
  name: "HeaderMixin",
  components: {
    NotificationIcon,
  },
  props: {
    userName: {
      required: true,
      type: String,
    },
  },
  setup() {
    const showNavigation = ref(false);
    const toggleNavigation = () => {
      showNavigation.value = !showNavigation.value;
      if (showNavigation.value) window.scrollTo(0, 0);
    };
    return { showNavigation, toggleNavigation };
  },
};
</script>

<style>
.app_bar_background {
  background: url("../assets/Backgrounds/header.svg") no-repeat top -5px left -5px !important;
  -webkit-background-size: 200% 100%;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-size: 100% 100%;
}
.drop_shadow {
  filter: drop-shadow(0px -15px 20px #000);
  background-color: #fcf8f8;
}
.padding_header {
  padding: 0 153px 0 121px;
}
.ghost_icon {
  margin-left: 8px;
  margin-right: 12px;
}
.user_name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: black;
  margin-right: 46px;
}
@media only screen and (min-width: 2500px) {
  .app_bar_background_xl {
    background-color: white !important;
  }
}
</style>
