<template>
  <button @click="$emit('toggleNavigation')">
    <svg
      v-if="!showNavigation"
      width="30"
      height="30"
      viewBox="0 0 32 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.2001 13.08H1.83014C1.04037 13.08 0.400146 13.7202 0.400146 14.51V17.95C0.400146 18.7398 1.04037 19.38 1.83014 19.38H30.2001C30.9899 19.38 31.6301 18.7398 31.6301 17.95V14.51C31.6301 13.7202 30.9899 13.08 30.2001 13.08Z"
        :fill="colorScheme['orange']"
      />
      <path
        d="M30.2001 0.869995H1.83014C1.04037 0.869995 0.400146 1.51023 0.400146 2.3V5.74C0.400146 6.52977 1.04037 7.16999 1.83014 7.16999H30.2001C30.9899 7.16999 31.6301 6.52977 31.6301 5.74V2.3C31.6301 1.51023 30.9899 0.869995 30.2001 0.869995Z"
        :fill="colorScheme['red']"
      />
      <path
        d="M30.2001 24.53H1.83014C1.04037 24.53 0.400146 25.1702 0.400146 25.96V29.4C0.400146 30.1897 1.04037 30.83 1.83014 30.83H30.2001C30.9899 30.83 31.6301 30.1897 31.6301 29.4V25.96C31.6301 25.1702 30.9899 24.53 30.2001 24.53Z"
        :fill="colorScheme['red']"
      />
    </svg>
    <svg
      v-else
      width="30"
      height="30"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.9125 1.36672L1.20323 25.0759C0.543248 25.7359 0.543202 26.806 1.20319 27.466L4.08111 30.3439C4.7411 31.0039 5.81119 31.0039 6.47118 30.3439L30.1804 6.63466C30.8404 5.97468 30.8404 4.90458 30.1804 4.24459L27.3025 1.36667C26.6425 0.706685 25.5724 0.70673 24.9125 1.36672Z"
        :fill="colorScheme['red']"
      />
      <path
        d="M30.1732 25.0722L6.46398 1.36302C5.80399 0.703038 4.7339 0.702992 4.07391 1.36298L1.19599 4.2409C0.536001 4.90089 0.536044 5.97098 1.19603 6.63097L24.9053 30.3402C25.5652 31.0002 26.6353 31.0002 27.2953 30.3402L30.1732 27.4623C30.8332 26.8023 30.8332 25.7322 30.1732 25.0722Z"
        :fill="colorScheme['red']"
      />
    </svg>
  </button>
</template>

<script>
import { colorScheme } from "@/constants";

export default {
  name: "NavigationButton",
  props: ["showNavigation"],
  emits: ["toggleNavigation"],
  setup() {
    return { colorScheme };
  },
};
</script>
