<template>
  <v-sheet class="pa-8 border-lg">
    <div class="font-poppins font-weight-regular title">
      My Death Mate
      <v-divider class="title-underline mb-6 mt-2"></v-divider>
    </div>
    <div class="font-poppins body-2">
      <div>So what is a death mate?</div>
      <div class="my-3">
        This is the person that you trust your life, and your afterlife with.
        This is the person that you choose to share your mortle portal with.
        They will have access to areas of your portal to help deliver your last
        wishes. It could be a partner, relative, best mate - whoever you think
        is best able to deliver on this final job for you.
      </div>
      <div class="my-3">
        Make sure you have a conversation with your chosen “Death Mate” so they
        know what they are in for and are comfortable being that person. Death
        can be a hard topic for some, and it`s not till you start having these
        conversation with them will you know how they feel. Just be prepared to
        have a back up or two up your sleeve.
      </div>
      <div class="my-3">
        If you have any other questions - reach out to us at
        <a href="mailto:mortle@mortle.me">mortle@mortle.me</a>
      </div>
    </div>
  </v-sheet>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "DeathMate",
  setup() {},
});
</script>

<style scoped>
/* import css */
@import url("@/assets/css/global.css");
.title-underline {
  margin-right: 80%;
}
</style>
