<template>
  <div class="sub_menu">
    <v-sheet height="100vh" width="232px">
      <div
        v-for="(section, index) in sections"
        :key="`sub-nav-link-${index}`"
        @click="
          selectedSection = index;
          $router.push({
            path: sections[index].path,
          });
        "
        :class="{
          selected_section: index === selectedSection,
          'mr-7 ml-9': index !== selectedSection,
          'mt-11': ![0, 1].includes(index - selectedSection),
          'd-flex flex-row cursor-pointer justify-space-between': true,
        }"
      >
        <v-hover v-slot="{ hover }">
          <div
            :class="{
              'text_style align-self-center': true,
              'ml-9': index === selectedSection,
              'red--text': hover,
              'black--text': !hover,
            }"
          >
            {{ section.text }}
          </div>
        </v-hover>
        <ChevronIcon v-if="index !== selectedSection" color="#999999" />
      </div>
    </v-sheet>
  </div>
</template>

<script>
import { sections } from "@/constants.js";
import ChevronIcon from "@/components/Common/Buttons/ChevronIcon.vue";
import { onMounted, ref } from "vue";
import { useRoute } from "vue2-helpers/vue-router";

export default {
  name: "SubMenuDesktop",
  components: {
    ChevronIcon,
  },
  setup() {
    const route = useRoute();
    const selectedSection = ref(0);
    onMounted(() => {
      selectedSection.value = sections.findIndex(
        (section) =>
          route.path.includes(section.id) || route.path === section.path
      );
    });
    return { sections, selectedSection };
  },
};
</script>

<style scoped>
.sub_menu {
  margin-left: 120px !important;
  max-width: 232px !important;
  flex: 0 0 25%;
  max-width: 25%;
  position: fixed;
  top: 0;
}
.v-sheet {
  padding-top: 110px;
}
.text_style {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  width: 126px;
  cursor: pointer;
}
.cursor-pointer {
  cursor: pointer;
}
.selected_section {
  background-image: url("../../assets/Backgrounds/selected_section.svg") !important;
  background-repeat: no-repeat !important;
  background-position: left 10px center !important;
  background-size: 100% 100% !important;
  height: 110px;
  width: 232px;
}
</style>
