<template>
  <div>
    <v-row no-gutters justify="space-between" class="mb-5" align="center">
      <component :is="icon" :width="46" :height="40" class="mr-5" />
      <v-col class="headText"> {{ title }} </v-col>
      <div>
        <v-fade-transition>
          <BackButton
            v-show="selectedTab !== 1"
            :color="color"
            class="mr-2"
            @click="
              selectedTab =
                selectedTab === 1
                  ? choices.length
                  : (selectedTab - 1) % (choices.length + 1)
            "
          />
        </v-fade-transition>
        <v-fade-transition>
          <NextButton
            v-show="selectedTab !== choices.length"
            :color="color"
            @click="
              selectedTab =
                selectedTab === choices.length
                  ? 1
                  : (selectedTab + 1) % (choices.length + 1)
            "
          />
        </v-fade-transition>
      </div>
    </v-row>
    <v-row>
      <v-col cols="8">
        <v-card
          flat
          rounded="lg"
          :min-height="$vuetify.breakpoint.smAndUp ? 570 : 450"
          class="py-6"
        >
          <div class="main_text" v-if="$vuetify.breakpoint.smAndUp">
            {{ choices.find((ele) => ele.id === selectedTab).text }}
          </div>
          <div
            :class="{
              'sub_text sub_text_margin': true,
              'mt-2 mb-5': !$vuetify.breakpoint.smAndUp,
            }"
          >
            {{ choices.find((ele) => ele.id === selectedTab).question }}
          </div>
          <component
            :is="choices.find((ele) => ele.id === selectedTab).component"
            :color="color"
            @completed="
              choices.find((ele) => ele.id === selectedTab).complete = true
            "
            @pending="
              choices.find((ele) => ele.id === selectedTab).complete = false
            "
            :class="choices.find((ele) => ele.id === selectedTab).classes"
          />
        </v-card>
      </v-col>
      <v-col cols="4">
        <TabSelection
          @updateTab="selectedTab = $event"
          :selectedTab="selectedTab"
          :color="color"
          size="large"
          :choices="choices"
        />
        <v-card flat rounded="lg" min-height="150" class="pa-5 pb-10 mb-5">
          <GuideText
            :textList="choices.find((ele) => ele.id === selectedTab).guideText"
          />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref } from "vue";
import NextButton from "@/components/Common/Buttons/NextButton.vue";
import BackButton from "@/components/Common/Buttons/BackButton.vue";
import BaseButton from "@/components/Common/Buttons/BaseButton.vue";
import LockIcon from "@/components/Common/Icons/LockIcon.vue";
import GraveIcon from "@/components/Common/Icons/GraveIcon.vue";
import HeartIcon from "@/components/Common/Icons/HeartIcon.vue";
import StarIcon from "@/components/Common/Icons/StarIcon.vue";
import BucketList from "@/Views/Arrangements/BucketList.vue";
import RememberMe from "@/Views/Arrangements/RememberMe.vue";
import MyEpitaph from "@/Views/Arrangements/MyEpitaph.vue";
import MyStory from "@/Views/Arrangements/MyStory.vue";
import GraveMessages from "@/Views/Arrangements/GraveMessages.vue";
import DieFulfilled from "@/Views/Arrangements/DieFulfilled.vue";
import TabSelection from "@/Views/Arrangements/TabSelection.vue";
import GuideText from "@/Views/Arrangements/GuideText.vue";
import FarewellToneTheme from "@/Views/Arrangements/FarewellToneTheme.vue";
import FarewellPriorities from "@/Views/Arrangements/FarewellPriorities.vue";
import FarewellAnythingElse from "@/Views/Arrangements/FarewellAnythingElse.vue";
import FarewellAfterparty from "@/Views/Arrangements/FarewellAfterparty.vue";
import FarewellSpeakers from "@/Views/Arrangements/FarewellSpeakers.vue";
import FarewellMusic from "@/Views/Arrangements/FarewellMusic.vue";
import FarewellMedia from "@/Views/Arrangements/FarewellMedia.vue";
import SensitiveInstructions from "@/Views/Arrangements/SensitiveInstructions.vue";
import SensitiveWill from "@/Views/Arrangements/SensitiveWill.vue";
import SensitiveDigitalfootprint from "@/Views/Arrangements/SensitiveDigitalfootprint.vue";

export default {
  name: "LifeArrangementsLarge",
  components: {
    NextButton,
    BackButton,
    GraveMessages,
    BaseButton,
    LockIcon,
    SensitiveDigitalfootprint,
    GraveIcon,
    RememberMe,
    TabSelection,
    FarewellMedia,
    FarewellMusic,
    FarewellSpeakers,
    GuideText,
    HeartIcon,
    BucketList,
    FarewellPriorities,
    DieFulfilled,
    FarewellToneTheme,
    FarewellAnythingElse,
    SensitiveInstructions,
    FarewellAfterparty,
    MyStory,
    StarIcon,
    MyEpitaph,
    SensitiveWill,
  },
  props: ["icon", "color", "title", "choices"],
  setup() {
    const selectedTab = ref(1);
    return {
      selectedTab,
    };
  },
};
</script>

<style scoped>
.headText {
  font-family: "Castoro";
  font-style: normal;
  font-weight: 400;
  font-size: 33px;
  line-height: 41px;
  color: black;
}
.selectedTab {
  border: 2px solid #ff6e3f;
}
.main_text {
  font-family: "Castoro";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #231f20;
  margin: 0 0 21px 32px;
}
.sub_text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #000;
}
.sub_text_margin {
  margin: 0 47px 0 32px;
}
</style>
