var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-sheet',{staticClass:"px-4 py-8"},[_c('div',{staticClass:"font-poppins font-weight-regular title ml-5"},[_vm._v(" FAQs "),_c('v-divider',{staticClass:"title-underline mb-6 mt-2"})],1),_c('BaseAccordian',{attrs:{"color":"orange","items":[
      {
        text: 'What`s this all about then?',
        value:
          'We have created an portal (Mortle Portal…get it?!) with the vision that one hundred thousand people will curate their afterlife and reflect on their lives to feel confident they will leave behind a positive legacy.',
      },
      {
        text: 'Who is mortle?',
        value:
          'The Mortle team is Dee, Charles, Tara, Kate, Lisa, Asender and our inner circle that have cheered us on to get to this point. Special shout out to Adam, Megham and [insert legal advice person].',
      },
      {
        text: 'Who cares?',
        value:
          'Mortle wants you to start conversations about dying so you can get on with living.  Don`t just leave it in the portal…talk about it with your inner circle. We appreciate the process is challenging but necessary if you need some further support visit https://checkpointorg.com/global/.',
      },
      { text: 'What does it cost?', value: 'Blah blah blah' },

      { text: 'Is my information safe?', value: 'Blah blah blah' },
      {
        text: 'What is a death mate?',
        value:
          'So what is a death mate?\nThis is the person that you trust your life, and your afterlife with. This is the person that you choose to share your mortle portal with. They will have access to areas of your portal to help deliver your last wishes.  It could be a partner, relative, best mate - whoever you think is best able to deliver on this final job for you.\nMake sure you have a conversation with your chosen “Death Mate” so they know what they are in for and are comfortable being that person. Death can be a hard topic for some, and it`s not till. ',
      },
      {
        text: 'This has been tough... is there someone I can talk to?',
        value: 'https://checkpointorg.com/global/',
      },
      {
        text: 'How can I let Mortle know what I think?',
        value: 'Get in touch mortle@mortle.me ',
      },
      {
        text: 'How can I stay in touch?',
        value:
          'Once you`ve signed up you will receive emails to update you on Mortle developments and reminders to update informration as life changes.  You can also follow Mortle at https://www.instagram.com/mortle.me/',
      },
      { text: 'Get in touch?', value: 'mortle@mortle.me' },
    ]}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }