<template>
  <div>
    <v-card
      :class="{
        'mt-5 d-flex align-content-space-around flex-wrap': true,
        'mt-5 mb-5': !$vuetify.breakpoint.smAndUp,
      }"
      elevation="0"
    >
      <v-card
        v-for="(choice, index) in themes"
        :key="index"
        class="pr-4 pt-4"
        width="160"
        elevation="0"
      >
        <BaseButton
          :color="color"
          :textColor="!choice.chosen ? color : 'white'"
          :text="choice.text"
          :outlined="!choice.chosen"
          :width="150"
          @click="choose(index)"
        />
      </v-card>
    </v-card>
    <div class="mt-9 subText">
      Explain how would your Deathmate bring your tone to `life` at your
      farwell?
    </div>
    <TextArea
      class="mt-3"
      :value.sync="answer"
      size="small"
      :disabled="!editMode"
    />
    <v-row no-gutters>
      <v-spacer />
      <v-fade-transition>
        <BaseButton
          v-show="editMode"
          @click="
            editMode = false;
            answer = value;
          "
          color="grey_dark"
          textColor="grey_dark"
          text="Cancel"
          :outlined="true"
          class="mr-2"
        />
      </v-fade-transition>
      <BaseButton
        v-if="editMode"
        @click="
          editMode = false;
          answer.length > 0 && $emit('completed');
        "
        :color="color"
        textColor="white"
        text="Save"
      />
      <BaseButton
        v-else
        @click="editMode = true"
        :color="color"
        textColor="white"
        text="Edit"
        class="ml-2"
      />
    </v-row>
    <div class="mt-6 subText">Select a colour palette of five colors:</div>
    <div class="d-flex flex-row justify-space-between">
      <div class="flex-column">
        <v-color-picker
          class="my-2 mx-0"
          :value="selectedColors[0]"
          hide-inputs
          @update:color="
            selectedColors.length > 5
              ? showSnackMessage(
                  'You can only select 5 colors for your palette'
                )
              : selectedColors.unshift($event.hex)
          "
          show-swatches
          hide-mode-switch
          hide-sliders
          hide-canvas
          swatches-max-height="150px"
        ></v-color-picker>
      </div>
      <div class="flex-column">
        <v-chip
          v-for="(color, index) in selectedColors"
          class="ml-2 cursor-pointer"
          @click="selectedColors.splice(index, 1)"
          :key="color"
          :color="color"
          pill
          small
        />
      </div>
    </div>
    <v-snackbar v-model="showSnackBar" :color="color" rounded="pill" outlined>
      {{ snackBarText }}
      <v-spacer></v-spacer>
      <template v-slot:action="{ attrs }">
        <v-btn :color="color" v-bind="attrs" text @click="showSnackBar = false">
          Ok
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { ref } from "vue";
import { toneThemeChoices } from "@/constants";
import TextArea from "@/components/Common/TextArea.vue";
import BaseButton from "@/components/Common/Buttons/BaseButton.vue";

export default {
  name: "FarewellToneTheme",
  emits: ["completed", "pending"],
  props: {
    color: {
      type: String,
      required: false,
      default: "orange",
    },
    value: {
      type: String,
      required: false,
      default: "Just make everyone extremely sad!\n\n- John",
    },
  },
  components: {
    TextArea,
    BaseButton,
  },
  setup(props, { emit }) {
    const themes = ref(toneThemeChoices.themes);
    const showSnackBar = ref(false);
    const answer = ref(props.value);
    const snackBarText = ref("");
    const selectedColors = ref(["#673AB7FF"]);
    const editMode = ref(false);
    const showSnackMessage = (message) => {
      snackBarText.value = message;
      showSnackBar.value = true;
    };
    const choose = (index) => {
      if (themes.value[index].chosen) {
        themes.value[index].chosen = false;
        emit("pending");
        return;
      }
      if (themes.value.filter((choice) => choice.chosen).length >= 3) {
        showSnackMessage("You can only choose 3 themes");
        emit("completed");
        return;
      }
      themes.value[index].chosen = true;
      if (themes.value.filter((choice) => choice.chosen).length == 3)
        emit("completed");
    };
    return {
      answer,
      snackBarText,
      selectedColors,
      showSnackBar,
      showSnackMessage,
      choose,
      themes,
      editMode,
    };
  },
};
</script>

<style scoped>
subText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #000;
}
</style>
