<template>
  <v-icon v-if="complete" :color="color">mdi-check-circle</v-icon>
  <v-icon v-else :color="color">mdi-checkbox-blank-circle-outline</v-icon>
</template>

<script>
export default {
  name: "CheckBox",
  props: {
    complete: {
      type: Boolean,
      default: false,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
};
</script>
