<template>
  <v-card
    min-height="197"
    rounded="xl"
    :color="state === 'active' ? color : 'white'"
    flat
    @click="
      !!id &&
        $router.push({
          path: `/arrangements/${id}/`,
        })
    "
  >
    <div class="d-flex flex-row align-self-center justify-space-between">
      <component
        :is="icon"
        :type="state === 'active' ? 'light' : 'dark'"
        :width="46"
        :height="40"
        class="ml-8 mt-4"
      />
      <div class="mr-8 pt-2">
        <ProgressNumber
          v-if="$vuetify.breakpoint.xs"
          :totalSteps="totalSteps"
          :completedSteps="completedSteps"
          :color="state !== 'active' ? color : 'white'"
        />
      </div>
    </div>
    <div
      :class="{
        'mt-7': !$vuetify.breakpoint.xs,
        'mt-5': $vuetify.breakpoint.xs,
        'ml-7 text_title': true,
        'black--text': state !== 'active',
        'white--text': state === 'active',
      }"
    >
      {{ title }}
    </div>
    <div
      :class="{
        'mt-3': !$vuetify.breakpoint.xs,
        'mt-1': $vuetify.breakpoint.xs,
        'mr-7 ml-7 text_description': true,
        'black--text': state !== 'active',
        'white--text': state === 'active',
      }"
    >
      {{ description }}
    </div>
    <ProgressDots
      :class="{ 'ml-7 my-3': true, 'mb-8 mt-2': $vuetify.breakpoint.xs }"
      :totalSteps="totalSteps"
      :completedSteps="completedSteps"
      :color="state !== 'active' ? color : 'white'"
    />
    <div
      class="ml-7 mb-7 d-flex flex-row align-self-center justify-space-between"
      v-if="!$vuetify.breakpoint.xs"
    >
      <ProgressNumber
        :totalSteps="totalSteps"
        :completedSteps="completedSteps"
        :color="state !== 'active' ? color : 'white'"
      />
      <v-spacer></v-spacer>
      <ChevronIcon
        :size="36"
        :color="state === 'active' ? 'white' : color"
        class="mr-9"
      />
    </div>
  </v-card>
</template>

<script>
import ProgressNumber from "@/components/Common/ProgressNumber.vue";
import ChevronIcon from "@/components/Common/Buttons/ChevronIcon.vue";
import ProgressDots from "@/components/Common/ProgressDots.vue";
import LockIcon from "@/components/Common/Icons/LockIcon.vue";
import GraveIcon from "@/components/Common/Icons/GraveIcon.vue";
import HeartIcon from "@/components/Common/Icons/HeartIcon.vue";
import StarIcon from "@/components/Common/Icons/StarIcon.vue";

export default {
  name: "ProgressCard",
  components: {
    ProgressNumber,
    LockIcon,
    GraveIcon,
    HeartIcon,
    StarIcon,
    ChevronIcon,
    ProgressDots,
  },
  props: {
    state: {
      required: false,
      default: "inactive",
      type: String,
    },
    color: {
      required: true,
      type: String,
    },
    completedSteps: {
      required: true,
      type: Number,
    },
    totalSteps: {
      required: true,
      type: Number,
    },
    icon: {
      required: true,
      type: String,
    },
    id: {
      required: false,
      type: String,
    },
    title: {
      required: true,
      type: String,
    },
    description: {
      required: true,
      type: String,
    },
  },
};
</script>

<style scoped>
.text_title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
}

.text_description {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 30px;
}
</style>
