<template>
  <v-btn
    depressed
    :color="color"
    :width="width"
    :tile="tile"
    :disabled="disabled"
    :rounded="rounded"
    :outlined="outlined"
    @click="$emit('click')"
  >
    <span :class="`${textColor}--text ${textSize}`">{{ text }}</span>
  </v-btn>
</template>

<script>
export default {
  name: "BaseButton",
  props: {
    color: {
      type: String,
      default: "orange",
      required: false,
    },
    textSize: {
      type: String,
      default: "body-2",
      required: false,
    },
    textColor: {
      type: String,
      default: "white",
      required: false,
    },
    text: {
      type: String,
      default: "+ Add Another",
      required: false,
    },
    tile: {
      type: Boolean,
      default: false,
      required: false,
    },
    rounded: {
      type: Boolean,
      default: true,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [Number, String],
      default: undefined,
      required: false,
    },
    outlined: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
};
</script>
