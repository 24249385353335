var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{class:{
      'mt-5 d-flex align-content-space-around flex-wrap': true,
      'mt-5 mb-5': !_vm.$vuetify.breakpoint.smAndUp,
    },attrs:{"elevation":"0"}},_vm._l((_vm.themes),function(choice,index){return _c('v-card',{key:index,staticClass:"pr-4 pt-4",attrs:{"width":"160","elevation":"0"}},[_c('BaseButton',{attrs:{"color":_vm.color,"textColor":!choice.chosen ? _vm.color : 'white',"text":choice.text,"outlined":!choice.chosen,"width":150},on:{"click":function($event){return _vm.choose(index)}}})],1)}),1),_c('div',{staticClass:"mt-9 subText"},[_vm._v(" Explain how would your Deathmate bring your tone to `life` at your farwell? ")]),_c('TextArea',{staticClass:"mt-3",attrs:{"value":_vm.answer,"size":"small","disabled":!_vm.editMode},on:{"update:value":function($event){_vm.answer=$event}}}),_vm._v(" "),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-spacer'),_c('v-fade-transition',[_c('BaseButton',{directives:[{name:"show",rawName:"v-show",value:(_vm.editMode),expression:"editMode"}],staticClass:"mr-2",attrs:{"color":"grey_dark","textColor":"grey_dark","text":"Cancel","outlined":true},on:{"click":function($event){_vm.editMode = false;
          _vm.answer = _vm.value;}}})],1),(_vm.editMode)?_c('BaseButton',{attrs:{"color":_vm.color,"textColor":"white","text":"Save"},on:{"click":function($event){_vm.editMode = false;
        _vm.answer.length > 0 && _vm.$emit('completed');}}}):_c('BaseButton',{staticClass:"ml-2",attrs:{"color":_vm.color,"textColor":"white","text":"Edit"},on:{"click":function($event){_vm.editMode = true}}})],1),_c('div',{staticClass:"mt-6 subText"},[_vm._v("Select a colour palette of five colors:")]),_c('div',{staticClass:"d-flex flex-row justify-space-between"},[_c('div',{staticClass:"flex-column"},[_c('v-color-picker',{staticClass:"my-2 mx-0",attrs:{"value":_vm.selectedColors[0],"hide-inputs":"","show-swatches":"","hide-mode-switch":"","hide-sliders":"","hide-canvas":"","swatches-max-height":"150px"},on:{"update:color":function($event){_vm.selectedColors.length > 5
            ? _vm.showSnackMessage(
                'You can only select 5 colors for your palette'
              )
            : _vm.selectedColors.unshift($event.hex)}}})],1),_c('div',{staticClass:"flex-column"},_vm._l((_vm.selectedColors),function(color,index){return _c('v-chip',{key:color,staticClass:"ml-2 cursor-pointer",attrs:{"color":color,"pill":"","small":""},on:{"click":function($event){return _vm.selectedColors.splice(index, 1)}}})}),1)]),_c('v-snackbar',{attrs:{"color":_vm.color,"rounded":"pill","outlined":""},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"color":_vm.color,"text":""},on:{"click":function($event){_vm.showSnackBar = false}}},'v-btn',attrs,false),[_vm._v(" Ok ")])]}}]),model:{value:(_vm.showSnackBar),callback:function ($$v) {_vm.showSnackBar=$$v},expression:"showSnackBar"}},[_vm._v(" "+_vm._s(_vm.snackBarText)+" "),_c('v-spacer')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }