<template>
  <div class="mt-8">
    <v-row class="justify-space-between community-search-box ma-0">
      <v-col cols="9" class="pl-7 py-6">
        <div
          class="font-weight-bold title community-search-head font-poppins white--text"
        >
          For the Community, by the Community
        </div>
        <v-combobox
          v-model.trim="searchValue"
          append-icon="mdi-comment-search"
          clear-icon="mdi-close-circle"
          label="Looking for an answer? Try searching in the community posts."
          color="orange"
          type="text"
          class="mt-5"
          :items="[]"
          outlined
          @click:append="searchValue = ''"
          @click:clear="searchValue = ''"
        >
          <template #no-data>
            <v-list-item
              ripple
              @mousedown.prevent
              @click="showCreatePostDialog = true"
            >
              <v-list-item-title>
                <span class="font-poppins font-weight-regular subtitle-2"
                  >Couldn't find your answer? Ask the community</span
                >
              </v-list-item-title>
              <v-icon color="orange" class="ml-2"> mdi-send-circle </v-icon>
            </v-list-item>
          </template>
        </v-combobox>
        <BaseButton
          color="orange"
          textColor="white"
          text="+ Create a post"
          @click="showCreatePostDialog = true"
        />
      </v-col>
      <v-col cols="3">
        <LottieAnimation
          class="m-h-200"
          :options="{ animationData: CommunityManagerAnimation }"
        />
      </v-col>
    </v-row>
    <v-row class="featured-content justify-space-between">
      <v-col xl="9" lg="9" md="9" xs="12" sm="12">
        <v-sheet height="280" class="border-lg pa-6">
          <div class="font-poppins font-weight-regular title">
            Featured Posts
          </div>
          <v-divider class="title-underline mb-6 mt-2"></v-divider>
          <v-carousel
            class="border-lg"
            :continuous="false"
            :cycle="true"
            :show-arrows="false"
            hide-delimiter-background
            height="160"
            delimiter-icon="mdi-minus"
          >
            <v-carousel-item v-for="(post, i) in featuredContent" :key="i">
              <div class="d-flex flex-row justify-space-between">
                <div
                  class="d-flex align-start flex-column mb-6 height-200 mr-2"
                >
                  <div class="mb-auto">
                    <div
                      class="font-poppins font-weight-semibold hover-cursor subtitle-1"
                    >
                      {{ post.title }}
                    </div>
                  </div>
                  <div class="font-poppins caption">
                    Published By: {{ post.author }}
                  </div>
                  <div class="font-poppins caption">
                    Published At: {{ post.date }}
                  </div>
                </div>
                <div class="flex-column">
                  <LottieAnimation
                    height="155px"
                    :options="{ animationData: post.animation }"
                  />
                </div>
              </div>
            </v-carousel-item>
          </v-carousel>
        </v-sheet>
        <v-sheet min-height="280" class="border-lg pa-6 my-2">
          <div
            class="d-flex flex-row justify-space-between font-poppins font-weight-regular title"
          >
            <div>Recent Posts</div>
            <div>
              <v-chip
                small
                @click="sortType = 'Most Viewed'"
                :color="sortType === 'Most Viewed' ? 'salmon' : ''"
                class="mr-2 white--text hover-cursor"
                >Most Viewed</v-chip
              >
              <v-chip
                small
                @click="sortType = 'Most Liked'"
                :color="sortType === 'Most Liked' ? 'salmon' : ''"
                class="mr-2 white--text hover-cursor"
                >Most Liked</v-chip
              >
              <v-chip
                small
                @click="sortType = 'Most Recent'"
                :color="sortType === 'Most Recent' ? 'salmon' : ''"
                class="white--text hover-cursor"
                >Most Recent</v-chip
              >
            </div>
          </div>
          <div
            v-for="post in sortedPosts"
            :key="post.id"
            class="d-flex post-box minor-box-shadow border-lg pa-2 my-7 flex-row justify-space-between"
          >
            <div class="d-flex align-start flex-column mb-6 height-200 mr-2">
              <div class="mb-auto">
                <div
                  class="font-poppins font-weight-semibold hover-cursor subtitle-1"
                >
                  {{ post.title }}
                </div>
                <div class="mt-4 mb-9 font-poppins body-2">
                  {{ post.content }}
                </div>
              </div>
              <div class="w-100 d-flex flex-row justify-space-between">
                <div class="flex-column">
                  <div class="font-poppins caption">
                    Published By: {{ post.author }}
                  </div>
                  <div class="font-poppins caption">
                    Published At: {{ post.date }}
                  </div>
                </div>
                <div class="flex-column">
                  <div class="font-poppins caption">
                    <v-icon x-small color="salmon" class="hover-cursor mr-2"
                      >mdi-thumb-up</v-icon
                    >
                    {{ post.likes }}
                  </div>
                  <div class="font-poppins caption">
                    <v-icon x-small color="salmon" class="mr-2">mdi-eye</v-icon>
                    {{ post.views }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-sheet>
      </v-col>
      <v-col xl="3" lg="3" md="3" xs="12" sm="12">
        <div class="border-lg upcoming-events pa-4">
          <div class="d-flex flex-row mb-2">
            <LottieAnimation
              width="100px"
              height="50px"
              class="mx-0"
              :options="{ animationData: CalendarAnimation }"
            />
            <div class="font-poppins font-weight-regular align-self-center">
              Upcoming Events
            </div>
          </div>
          <div
            v-for="(event, index) in events"
            :key="index"
            class="event-card hover-cursor minor-box-shadow d-flex flex-row px-2"
          >
            <div class="flex-column my-auto">
              <div class="wue-date">
                <span class="wue-date-month">{{ event.month }}</span>
                <span class="wue-date-day">{{ event.date }}</span>
              </div>
            </div>
            <div
              class="pl-2 caption my-auto overflow-ellipsis flex-column font-weight-regular font-poppins"
            >
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <span class="font-weight-regular" v-on="on">
                    {{ event.title }}
                  </span>
                </template>
                <span>{{ event.title }}</span>
              </v-tooltip>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <BaseModal
      :showModal.sync="showCreatePostDialog"
      :showToolBar="false"
      buttonText="Save"
      buttonIcon="mdi-content-save"
      toolbarTextColor="white"
      toolbarColor="orange"
      btnColor="orange"
      @button-click="createPost"
      width="600px"
    >
      <template #content>
        <div class="pt-6">
          <v-row no-gutters>
            <v-text-field
              v-model="title"
              :color="color"
              :rules="[() => !!title || 'title field is required']"
              single-line
              label="Title"
              class="mx-7 mt-3"
              filled
            />
          </v-row>
          <v-row no-gutters>
            <v-textarea
              v-model="description"
              :color="color"
              label="Detailed description..."
              class="mx-7 mt-3"
              filled
            />
          </v-row>
        </div>
      </template>
    </BaseModal>
  </div>
</template>

<script>
import CommunityManagerAnimation from "@/assets/Animations/community_manager.json";
import CalendarAnimation from "@/assets/Animations/calendar.json";
import PlanningAnimation from "@/assets/Animations/planning.json";
import JourneyAnimation from "@/assets/Animations/journey.json";
import PathAnimation from "@/assets/Animations/path.json";
import LottieAnimation from "@/components/Common/LottieAnimation.vue";
import { ref, computed } from "vue";
import BaseButton from "@/components/Common/Buttons/BaseButton.vue";
import BaseModal from "@/components/Common/BaseModal.vue";

export default {
  name: "CommunityHome",
  components: {
    LottieAnimation,
    BaseModal,
    BaseButton,
  },
  setup() {
    const searchValue = ref("");
    const featuredContent = [
      {
        id: 1,
        author: "Peter Parker",
        title: "Path to get started with Mortle",
        date: "Dec 02, 2022",
        animation: PathAnimation,
      },
      {
        id: 2,
        author: "John Doe",
        title: "Why should you share your journey",
        animation: JourneyAnimation,
        date: "Jan 01, 2021",
      },
      {
        id: 3,
        author: "Tony Stark",
        title: "How mortle can help you",
        animation: PlanningAnimation,
        date: "Oct 19, 2021",
      },
    ];
    const showCreatePostDialog = ref(false);
    const title = ref("");
    const description = ref("");
    const posts = [
      {
        id: 1,
        author: "Peter Parker",
        title: "Path to get started with Mortle",
        date: "2015-03-25",
        likes: 10,
        views: 100,
        content:
          "lorem ipsum dolor sit amet, consectetur adipiscing elit. lorem ipsum dolor sit amet, consectetur adipiscing elit. lorem ipsum dolor sit amet, consectetur adipiscing elit. lorem ipsum dolor sit amet, consectetur adipiscing elit. lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      },
      {
        id: 2,
        author: "John Doe",
        title: "Why should you share your journey",
        likes: 120,
        views: 100,
        content:
          "lorem ipsum dolor sit amet, consectetur adipiscing elit. lorem ipsum dolor sit amet, consectetur adipiscing elit. lorem ipsum dolor sit amet, consectetur adipiscing elit. lorem ipsum dolor sit amet, consectetur adipiscing elit. lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        date: "2015-04-25",
      },
      {
        id: 3,
        author: "Tony Stark",
        title: "How mortle can help you",
        likes: 1,
        content:
          "lorem ipsum dolor sit amet, consectetur adipiscing elit. lorem ipsum dolor sit amet, consectetur adipiscing elit. lorem ipsum dolor sit amet, consectetur adipiscing elit. lorem ipsum dolor sit amet, consectetur adipiscing elit. lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        views: 130,
        date: "2015-06-25",
      },
    ];
    const events = [
      {
        id: 1,
        date: "21",
        month: "Dec",
        title: "Mission of the community",
      },
      {
        id: 2,
        date: "02",
        month: "Jan",
        title: "Why you should share what you know",
      },
      {
        id: 3,
        date: "19",
        month: "Feb",
        title: "Understanding the importance of being a part of the community",
      },
    ];
    const sortType = ref("Most Recent");
    const sortedPosts = computed(() => {
      const temp = [...posts];
      temp.sort((a, b) => {
        if (sortType.value === "Most Recent") {
          return new Date(b.date) - new Date(a.date);
        } else if (sortType.value === "Most Liked") {
          return b.likes - a.likes;
        } else if (sortType.value === "Most Viewed") {
          return b.views - a.views;
        }
      });
      return temp;
    });
    const createPost = () => {
      if (title.value && description.value) {
        posts.push({
          id: posts.length + 1,
          author: "John Doe",
          title: title.value,
          content: description.value,
          date: new Date().toISOString().split("T")[0],
        });
        title.value = "";
        description.value = "";
        showCreatePostDialog.value = false;
      }
    };
    return {
      CommunityManagerAnimation,
      CalendarAnimation,
      title,
      description,
      searchValue,
      events,
      sortType,
      sortedPosts,
      showCreatePostDialog,
      posts,
      featuredContent,
      createPost,
    };
  },
};
</script>

<style scoped>
/* import css */
@import url("@/assets/css/global.css");
.community-search-head {
  background-color: #f9ebeb;
  border-radius: 16px;
  padding: 24px;
}
.title-underline {
  margin-right: 70%;
}
.featured-content {
  min-height: 350px;
}
.wue-date {
  min-width: 41px;
  min-height: 43px;
  max-width: 41px;
  max-height: 43px;
  border-radius: 12px;
  background: #f7faf7;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.wue-date-month {
  background-color: #e3c8c9;
  border-radius: 8px 8px 0px 0px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.022em;
  color: white;
  width: 100%;
  height: 100%;
}
.wue-date-day {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.02em;
  color: black;
}
.community-search-box {
  background-color: white;
  border-radius: 8px;
  padding: 0px 16px;
}
.upcoming-events {
  height: 320px;
  overflow-y: scroll;
  background-color: white;
}
.event-card {
  height: 70px;
  border-radius: 16px;
  margin-bottom: 8px;
}
</style>
