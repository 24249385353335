import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import { colorScheme } from "@/constants";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: colorScheme,
    },
  },
});
