<template>
  <v-slider
    :label="label"
    :value="value"
    @update:value="$emit('update:value', $event)"
    :max="maxValue"
    :min="minValue"
    :color="color"
    :step="step"
    :tick-size="tickSize"
    :ticks="showTicks"
    :track-color="trackColor"
    :tick-labels="tickLabels"
    :thumb-label="thumbLabel"
  >
    <template v-slot:append v-if="showValue">
      <v-text-field
        :value="value"
        @update:value="$emit('update:value', $event)"
        class="mt-0 pt-0"
        type="number"
        style="width: 60px"
      ></v-text-field>
    </template>
  </v-slider>
</template>

<script>
export default {
  name: "TheSlider",
  props: {
    thumbLabel: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "orange",
    },
    trackColor: {
      type: String,
      default: "orange",
    },
    tickLabels: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: "Slider",
    },
    value: {
      type: Number,
      default: 0,
    },
    maxValue: {
      type: Number,
      default: 100,
    },
    minValue: {
      type: Number,
      default: -100,
    },
    showValue: {
      type: Boolean,
      default: false,
    },
    showTicks: {
      type: [Boolean, String],
      default: false,
    },
    tickSize: {
      type: Number,
      default: 25,
    },
    step: {
      type: Number,
      default: 20,
    },
  },
};
</script>
