var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub_menu"},[_c('v-sheet',{attrs:{"height":"100vh","width":"232px"}},_vm._l((_vm.sections),function(section,index){return _c('div',{key:`sub-nav-link-${index}`,class:{
        selected_section: index === _vm.selectedSection,
        'mr-7 ml-9': index !== _vm.selectedSection,
        'mt-11': ![0, 1].includes(index - _vm.selectedSection),
        'd-flex flex-row cursor-pointer justify-space-between': true,
      },on:{"click":function($event){_vm.selectedSection = index;
        _vm.$router.push({
          path: _vm.sections[index].path,
        });}}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',{class:{
            'text_style align-self-center': true,
            'ml-9': index === _vm.selectedSection,
            'red--text': hover,
            'black--text': !hover,
          }},[_vm._v(" "+_vm._s(section.text)+" ")])]}}],null,true)}),(index !== _vm.selectedSection)?_c('ChevronIcon',{attrs:{"color":"#999999"}}):_vm._e()],1)}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }