<template>
  <div class="notification_icon">
    <HelpNotficationPlusMinusIcon :color="color" type="exclamation" />
    <svg
      v-if="totalUnreadNotifications > 0"
      class="notification_number_icon"
      width="20"
      height="20"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.21008 14.5C11.0761 14.5 14.2101 11.366 14.2101 7.5C14.2101 3.63401 11.0761 0.5 7.21008 0.5C3.34409 0.5 0.210083 3.63401 0.210083 7.5C0.210083 11.366 3.34409 14.5 7.21008 14.5Z"
        :fill="colorScheme[notificationColor]"
      />
    </svg>
    <span
      v-if="totalUnreadNotifications > 0"
      class="notification_number white--text"
    >
      {{ totalUnreadNotifications }}
    </span>
  </div>
</template>

<script>
import HelpNotficationPlusMinusIcon from "@/components/Common/Icons/HelpNotficationPlusMinusIcon.vue";
import { colorScheme } from "@/constants";

export default {
  name: "NotificationIcon",
  components: {
    HelpNotficationPlusMinusIcon,
  },
  props: {
    totalUnreadNotifications: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: "golden",
    },
    notificationColor: {
      type: String,
      default: "orange",
      required: false,
    },
  },
  setup() {
    return { colorScheme };
  },
};
</script>

<style scoped>
.notification_icon {
  position: relative;
}
.notification_number_icon {
  position: absolute;
  top: -2px;
  right: -8px;
}
.notification_number {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 10px;
  font-weight: 800;
}
</style>
