<template>
  <div>
    <div>
      <v-text-field
        v-model="inputUrl"
        :color="color"
        single-line
        label="Enter Your URL..."
        :disabled="!editInputUrlMode"
        filled
      />
      <v-row no-gutters>
        <v-spacer />
        <v-fade-transition>
          <BaseButton
            v-show="editInputUrlMode"
            @click="
              editInputUrlMode = false;
              inputUrl = '';
            "
            color="grey_dark"
            textColor="grey_dark"
            text="Cancel"
            :outlined="true"
            class="mr-2"
          />
        </v-fade-transition>
        <BaseButton
          v-if="editInputUrlMode"
          @click="editInputUrlMode = false"
          :color="color"
          textColor="white"
          text="Save"
        />
        <BaseButton
          v-else
          @click="editInputUrlMode = true"
          :color="color"
          textColor="white"
          text="Edit"
          class="ml-2"
        />
      </v-row>
    </div>
    <div class="mt-9">
      <div class="mb-1 subText">
        Add your songs and music to your farewell playlist. You can add as many
        as you like.
      </div>
      <v-row no-gutters class="mb-4">
        <v-spacer />
        <BaseButton
          :color="color"
          textColor="white"
          text="+ Add a song"
          @click="showModal = true"
        />
      </v-row>
      <div class="songList">
        <v-row>
          <v-col cols="3" class="item_name font-weight-bold">Artist</v-col>
          <v-col cols="3" class="item_name font-weight-bold">Song</v-col>
          <v-col cols="3" class="item_name font-weight-bold">Note</v-col>
          <v-col cols="3" class="item_name font-weight-bold">Actions</v-col>
        </v-row>
        <v-divider class="mt-2" :color="color"></v-divider>
        <div v-for="item in songList" :key="`${item.id}-list`">
          <v-row class="my-1 py-1">
            <v-col cols="3" class="item_name text-truncate">{{
              item.artist || "-"
            }}</v-col>
            <v-col cols="3" class="item_name text-truncate">{{
              item.song || "-"
            }}</v-col>
            <v-col cols="3" class="item_name text-truncate">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    {{ item.note || "-" }}
                  </span>
                </template>
                <span>{{ item.note || "-" }}</span>
              </v-tooltip>
            </v-col>
            <v-col cols="3" class="edit_delete">
              <button
                @click="
                  input = item;
                  selectedItemToEdit = item.id;
                  showModal = true;
                "
              >
                Edit
              </button>
              <span class="mx-1">|</span>
              <button
                @click="songList = songList.filter((ele) => ele.id !== item.id)"
              >
                Delete
              </button></v-col
            >
          </v-row>
          <v-divider :color="color"></v-divider>
        </div>
      </div>
    </div>
    <BaseModal
      :showModal.sync="showModal"
      :showToolBar="false"
      buttonText="Save"
      buttonIcon="mdi-content-save"
      toolbarTextColor="white"
      :toolbarColor="color"
      :btnColor="color"
      @button-click="saveSong"
      width="600px"
    >
      <template #content>
        <div class="pt-6">
          <v-row no-gutters>
            <v-col
              ><v-text-field
                v-model="input.artist"
                :color="color"
                :rules="[() => !!input.artist || 'artist field is required']"
                single-line
                label="Artist"
                class="mx-7 mt-3"
                filled
            /></v-col>
            <v-col
              ><v-text-field
                v-model="input.song"
                :color="color"
                :rules="[() => !!input.song || 'song field is required']"
                single-line
                label="Song"
                class="mx-7 mt-3"
                filled
            /></v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-text-field
                v-model="input.note"
                :color="color"
                single-line
                label="Your note..."
                class="mx-7 mt-3"
                filled
              />
            </v-col>
          </v-row>
        </div>
      </template>
    </BaseModal>
  </div>
</template>

<script>
import { ref } from "vue";
import BaseButton from "@/components/Common/Buttons/BaseButton.vue";
import BaseModal from "@/components/Common/BaseModal.vue";

export default {
  name: "FarewellMusic",
  components: { BaseButton, BaseModal },
  props: {
    color: {
      type: String,
      required: false,
      default: "orange",
    },
  },
  setup() {
    const input = ref({});
    const inputUrl = ref("");
    const showModal = ref(false);
    const editInputUrlMode = ref(false);
    const selectedItemToEdit = ref(null);
    const songList = ref([
      {
        id: 1,
        artist: "megham",
        note: "lorem isjjposh",
        song: "Lorem a",
        complete: true,
      },
      {
        id: 2,
        artist: "megham",
        note: "random text fummmy",
        song: "Lorem b",
        complete: false,
      },
      {
        id: 3,
        artist: "megham",
        note: "random text fummmy",
        song: "Lorem c",
        complete: false,
      },
      {
        id: 4,
        artist: "megham",
        note: "random text fummmy",
        song: "Lorem d",
        complete: false,
      },
      {
        id: 5,
        artist: "megham",
        note: "random text fummmy",
        song: "Lorem e",
        complete: false,
      },
      {
        id: 6,
        artist: "megham",
        note: "lorem isjjposh",
        song: "Lorem f",
        complete: false,
      },
      {
        id: 7,
        artist: "megham",
        note: "lorem isjjposh",
        song: "Lorem g",
        complete: false,
      },
      {
        id: 8,
        artist: "megham",
        note: "lorem isjjposh",
        song: "Lorem h",
        complete: false,
      },
      {
        id: 9,
        artist: "megham",
        note: "lorem isjjposh",
        song: "Lorem j",
        complete: false,
      },
      {
        id: 10,
        artist: "megham",
        note: "lorem isjjposh",
        song: "Lorem k",
        complete: false,
      },
      {
        id: 11,
        artist: "megham",
        note: "lorem isjjposh",
        song: "Lorem l",
        complete: false,
      },
      {
        id: 12,
        artist: "megham",
        note: "lorem isjjposh",
        song: "Lorem m",
        complete: false,
      },
      {
        id: 13,
        artist: "megham",
        note: "lorem isjjposh",
        song: "Lorem n",
        complete: false,
      },
    ]);
    const saveSong = () => {
      selectedItemToEdit.value !== null
        ? (songList.value = songList.value.map((ele) =>
            ele.id === selectedItemToEdit.value ? input.value : ele
          ))
        : songList.value.push({
            id: songList.value.length + 1,
            ...input.value,
          });
      selectedItemToEdit.value = null;
      input.value = {};
      showModal.value = false;
    };
    return {
      input,
      editInputUrlMode,
      inputUrl,
      selectedItemToEdit,
      showModal,
      songList,
      saveSong,
    };
  },
};
</script>

<style scoped>
.v-btn {
  text-transform: initial !important;
}
.edit_delete {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 22px;
  color: #919396;
}
.item_name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: black;
}
.hover-cursor:hover {
  cursor: pointer;
}
.v-text-field {
  border-radius: 8px;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 22px !important;
}
.songList {
  overflow: scroll;
  max-height: 40vh;
}
.subText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #000;
}
</style>
