<template>
  <router-link to="/">
    <svg
      width="66"
      height="51"
      viewBox="0 0 68 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M52.19 1.99997V50.51C52.19 51.21 51.62 51.79 50.91 51.79H43.93C43.23 51.79 42.65 51.22 42.65 50.51V32.03C42.65 31.44 41.82 31.32 41.65 31.88L38 44.06C37.84 44.6 37.34 44.97 36.78 44.97H30.15C29.59 44.97 29.09 44.6 28.93 44.06L25.28 31.88C25.11 31.32 24.28 31.44 24.28 32.03V50.51C24.28 51.21 23.71 51.79 23 51.79H16.02C15.32 51.79 14.74 51.22 14.74 50.51V1.99997C14.74 1.29997 15.31 0.719971 16.02 0.719971H23.51C24.07 0.719971 24.57 1.08997 24.73 1.62997L32.98 29.13C33.13 29.61 33.81 29.61 33.96 29.13L42.21 1.62997C42.37 1.08997 42.87 0.719971 43.43 0.719971H50.92C51.62 0.719971 52.2 1.28997 52.2 1.99997H52.19Z"
        :fill="colorScheme[mColor]"
      />
      <path
        d="M41.66 31.89L38.01 44.07C37.85 44.61 37.35 44.98 36.79 44.98H30.16C29.6 44.98 29.1 44.61 28.94 44.07L25.29 31.89C25.12 31.33 14.76 1.99997 14.76 1.99997C14.76 1.29997 15.33 0.719971 16.04 0.719971H23.53C24.09 0.719971 24.59 1.08997 24.75 1.62997L33 29.13C33.15 29.61 33.83 29.61 33.98 29.13L42.23 1.62997C42.39 1.08997 42.89 0.719971 43.45 0.719971H50.94C51.64 0.719971 52.22 1.28997 52.22 1.99997"
        :fill="colorScheme[mColor]"
      />
      <path
        d="M64.9799 23.85H3.00995C1.73417 23.85 0.699951 24.8842 0.699951 26.16V31.31C0.699951 32.5858 1.73417 33.62 3.00995 33.62H64.9799C66.2557 33.62 67.2899 32.5858 67.2899 31.31V26.16C67.2899 24.8842 66.2557 23.85 64.9799 23.85Z"
        :fill="colorScheme[barColor]"
      />
      <path
        d="M24.27 18.5H14.74V37.2H24.27V18.5Z"
        :fill="colorScheme[mColor]"
      />
      <path
        d="M52.1899 18.5H42.6599V37.2H52.1899V18.5Z"
        :fill="colorScheme[mColor]"
      />
    </svg>
  </router-link>
</template>

<script>
import { colorScheme } from "@/constants";

export default {
  name: "InitialsLogo",
  props: ["barColor", "mColor"],
  setup() {
    return { colorScheme };
  },
};
</script>
