<template>
  <div>
    <v-row no-gutters>
      <v-col
        ><v-text-field
          v-model="input.first_name"
          :color="color"
          :disabled="!editMode"
          :rules="[() => !!input.first_name || 'first name field is required']"
          single-line
          label="First Name"
          class="mr-2 mt-3"
          filled
      /></v-col>
      <v-col
        ><v-text-field
          v-model="input.last_name"
          :color="color"
          :rules="[() => !!input.last_name || 'last name field is required']"
          :disabled="!editMode"
          single-line
          label="Last Name"
          class="ml-2 mt-3"
          filled
      /></v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-text-field
          v-model="input.message"
          :color="color"
          :disabled="!editMode"
          single-line
          label="Your message..."
          class="mt-3"
          filled
        />
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-3">
      <v-spacer />
      <v-fade-transition>
        <BaseButton
          v-show="editMode"
          @click="editMode = false"
          color="grey_dark"
          textColor="grey_dark"
          text="Cancel"
          :outlined="true"
          class="mr-2"
        />
      </v-fade-transition>
      <BaseButton
        v-if="editMode"
        @click="editMode = false"
        :color="color"
        textColor="white"
        text="Save"
      />
      <BaseButton
        v-else
        @click="editMode = true"
        :color="color"
        textColor="white"
        text="Edit"
        class="ml-2"
      />
    </v-row>
  </div>
</template>

<script>
import { ref } from "vue";
import BaseButton from "@/components/Common/Buttons/BaseButton.vue";

export default {
  name: "SensitiveInstructions",
  components: { BaseButton },
  props: {
    color: {
      type: String,
      required: false,
      default: "orange",
    },
  },
  setup() {
    const input = ref({});
    const editMode = ref(false);
    return {
      input,
      editMode,
    };
  },
};
</script>

<style scoped>
.v-btn {
  text-transform: initial !important;
}
.edit_delete {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 22px;
  color: #919396;
}
.item_name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: black;
}
.hover-cursor:hover {
  cursor: pointer;
}
.v-text-field {
  border-radius: 8px;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 22px !important;
}
.guestList {
  overflow: scroll;
  max-height: 40vh;
}
.subText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #000;
}
</style>
