<template>
  <div>
    <v-app-bar
      app
      height="98"
      :class="{
        padding_header: true,
        app_bar_background: true,
        drop_shadow: !showNavigation,
      }"
      flat
    >
      <FullLogo />
      <v-spacer></v-spacer>
      <NotificationIcon :totalUnreadNotifications="2" />
      <GhostIcon class="ghost_icon" :height="40" :width="41" />
      <span class="user_name">G'day {{ userName }}</span>
      <NavigationButton
        :showNavigation="showNavigation"
        @toggleNavigation="toggleNavigation"
      />
    </v-app-bar>
    <TabletNavigation v-if="showNavigation" />
  </div>
</template>

<script>
import GhostIcon from "@/components/Common/Icons/GhostIcon.vue";
import TabletNavigation from "@/components/NavigationModals/TabletNavigation.vue";
import HeaderMixin from "@/Mixins/HeaderMixin.vue";
import FullLogo from "@/components/Common/Logos/FullLogo.vue";
import NavigationButton from "@/components/Common/Buttons/NavigationButton.vue";

export default {
  name: "TabletHeader",
  components: { TabletNavigation, GhostIcon, NavigationButton, FullLogo },
  mixins: [HeaderMixin],
};
</script>

<style scoped>
.padding_header {
  padding: 0 76px 0 75px;
}
</style>
