<template>
  <textarea
    :disabled="disabled"
    type="text"
    v-model="input"
    auto-grow
    :class="{
      'large-textarea': size === 'large',
      'small-textarea': size === 'small',
      'medium-textarea': size === 'medium',
      'x-small-textarea': size === 'x-small',
    }"
    placeholder="Type Here..."
  >
  </textarea>
</template>

<script>
export default {
  name: "TextArea",
  props: {
    value: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "large",
    },
  },
  computed: {
    input: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("update:value", val);
      },
    },
  },
};
</script>

<style scoped>
textarea {
  align-items: flex-start;
  padding: 8px 16px;
  gap: 10px;
  background: #f5f5f5;
  min-height: 340px;
  width: 100%;
  border-radius: 8px;
}
.large-textarea {
  min-height: 340px !important;
}
.medium-textarea {
  min-height: 190px !important;
}
.small-textarea {
  min-height: 140px !important;
}
.x-small-textarea {
  min-height: 90px !important;
}
textarea:focus {
  outline: none;
}
textarea[type="text"] {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}
</style>
