<template>
  <div>
    <v-card
      :class="{
        'mt-5': true,
        'mt-5 mb-5': !$vuetify.breakpoint.smAndUp,
      }"
      elevation="0"
    >
      <v-row v-for="(choice, index) in priorities" :key="index">
        <v-col>
          <BaseButton
            :color="color"
            :textColor="!choice.chosen ? color : 'white'"
            :text="choice.text"
            :outlined="!choice.chosen"
            width="100%"
            @click="choose(index)"
          />
        </v-col>
        <v-col>
          <v-fade-transition>
            <TheSlider
              v-show="choice.chosen"
              :value="choice.priority"
              :color="color"
              :trackColor="color"
              label="Priority"
              :step="1"
              :showValue="false"
              :tickSize="3"
              showTicks="always"
              :maxValue="5"
              :thumbLabel="true"
              :minValue="1"
            />
          </v-fade-transition>
        </v-col>
      </v-row>
    </v-card>
    <div class="mt-9 subText">
      Have any arrangements been made for the above or anything you would like
      to explain?
    </div>
    <TextArea
      class="mt-3"
      :value.sync="answer"
      size="small"
      :disabled="!editMode"
    />
    <v-row no-gutters>
      <v-spacer />
      <v-fade-transition>
        <BaseButton
          v-show="editMode"
          @click="
            editMode = false;
            answer = value;
          "
          color="grey_dark"
          textColor="grey_dark"
          text="Cancel"
          :outlined="true"
          class="mr-2"
        />
      </v-fade-transition>
      <BaseButton
        v-if="editMode"
        @click="
          editMode = false;
          answer.length > 0 && $emit('completed');
        "
        :color="color"
        textColor="white"
        text="Save"
      />
      <BaseButton
        v-else
        @click="editMode = true"
        :color="color"
        textColor="white"
        text="Edit"
        class="ml-2"
      />
    </v-row>
    <v-snackbar v-model="showSnackBar" :color="color" rounded="pill" outlined>
      {{ snackBarText }}
      <v-spacer></v-spacer>
      <template v-slot:action="{ attrs }">
        <v-btn :color="color" v-bind="attrs" text @click="showSnackBar = false">
          Ok
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { ref } from "vue";
import TheSlider from "@/components/Common/TheSlider.vue";
import { farewellPriorityChoices } from "@/constants";
import TextArea from "@/components/Common/TextArea.vue";
import BaseButton from "@/components/Common/Buttons/BaseButton.vue";

export default {
  name: "FarewellPriorities",
  emits: ["completed", "pending"],
  props: {
    color: {
      type: String,
      required: false,
      default: "orange",
    },
    value: {
      type: String,
      required: false,
      default: "I've already baught the Coffin!\n\n- John",
    },
  },
  components: {
    TextArea,
    TheSlider,
    BaseButton,
  },
  setup(props, { emit }) {
    const showSnackBar = ref(false);
    const editMode = ref(false);
    const snackBarText = ref("");
    const answer = ref(props.value);
    const priorities = ref(farewellPriorityChoices);
    const choose = (index) => {
      if (priorities.value[index].chosen) {
        priorities.value[index].chosen = false;
        emit("pending");
        return;
      }
      if (priorities.value.filter((choice) => choice.chosen).length >= 5) {
        snackBarText.value = "You can only choose 5 priorities";
        showSnackBar.value = true;
        emit("completed");
        return;
      }
      priorities.value[index].chosen = true;
      if (priorities.value.filter((choice) => choice.chosen).length == 3)
        emit("completed");
    };
    return {
      answer,
      snackBarText,
      showSnackBar,
      choose,
      priorities,
      editMode,
    };
  },
};
</script>

<style scoped>
.subText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #000;
}
</style>
