<template>
  <div class="navigation_div">
    <v-row>
      <v-col class="sub_menu" cols="5">
        <SubMenuTablet />
      </v-col>
      <v-col cols="7">
        <NavigationList source="tablet" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import NavigationList from "@/components/Common/NavigationList.vue";
import SubMenuTablet from "@/components/SubNavigation/SubMenuTablet.vue";

export default {
  name: "TabletNavigation",
  components: { NavigationList, SubMenuTablet },
};
</script>

<style scoped>
.navigation_div {
  position: absolute;
  top: 80px;
  width: 100%;
  z-index: 1;
  height: 750px;
  background-image: url("../../assets/Backgrounds/navigation.svg") !important;
  background-repeat: no-repeat !important;
  background-position: top -20px center;
  background-size: cover !important;
  padding: 0 82px 0 76px;
}
.sub_menu {
  background: #f4d7d8;
  margin-top: 57px;
  border-radius: 28px;
  padding: 34px 0 0 0;
  border: 0.71px solid #ffffff;
}
</style>
