<template>
  <v-btn rounded :color="color" elevation="0" @click="$emit('click')">
    <ChevronIcon color="white" :size="36" :circular="true" direction="left" />
    <span class="button_text">back</span>
  </v-btn>
</template>

<script>
import ChevronIcon from "@/components/Common/Buttons/ChevronIcon.vue";

export default {
  name: "BackButton",
  props: ["color"],
  components: {
    ChevronIcon,
  },
};
</script>

<style scoped>
.button_text {
  margin: 0 12.33px 0 9.33px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-transform: lowercase;
  color: #ffffff;
}
.v-btn {
  padding: 0 !important;
}
</style>
