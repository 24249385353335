<template>
  <div class="padding_desktop navigation_div">
    <NavigationList />
  </div>
</template>

<script>
import NavigationList from "@/components/Common/NavigationList.vue";

export default {
  name: "DesktopNavigation",
  components: { NavigationList },
};
</script>

<style scoped>
.navigation_div {
  position: absolute;
  top: 80px;
  width: 100%;
  z-index: 1;
  height: 600px;
  background-image: url("../../assets/Backgrounds/navigation.svg") !important;
  background-repeat: no-repeat !important;
  background-position: top -20px left -20px;
  background-size: cover !important;
}
.padding_desktop {
  padding: 0 153px 0 121px;
}
</style>
