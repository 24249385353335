var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-text-field',{staticClass:"mt-3",attrs:{"color":_vm.color,"single-line":"","label":"Type here","filled":""},scopedSlots:_vm._u([(!_vm.$vuetify.breakpoint.smAndUp)?{key:"append-outer",fn:function(){return [_c('HelpNotficationPlusMinusIcon',{attrs:{"color":_vm.color,"type":"plus"},on:{"click":_vm.addItem}})]},proxy:true}:null],null,true),model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}}),(_vm.$vuetify.breakpoint.smAndUp)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-spacer'),_c('BaseButton',{attrs:{"color":_vm.color,"disabled":!_vm.input,"textColor":"white","text":_vm.selectedItemToEdit === null ? '+ Add Another' : 'Save'},on:{"click":_vm.addItem}})],1):_vm._e(),_c('div',{staticClass:"itemList"},_vm._l((_vm.itemList),function(item){return _c('div',{key:`${item.text}-list`},[_c('div',{staticClass:"d-flex flex-row justify-space-between"},[_c('div',{staticClass:"align-self-center my-3 hover-cursor",on:{"click":function($event){item.complete = !item.complete;
            _vm.$emit(
              _vm.itemList.filter((c) => c.complete).length === _vm.itemList.length
                ? 'completed'
                : 'pending'
            );}}},[_c('CheckBox',{staticClass:"my-2 mr-3",attrs:{"color":_vm.color,"complete":item.complete}}),_c('span',{staticClass:"item_name text-truncate"},[_vm._v(_vm._s(item.text))])],1),_c('div',{staticClass:"align-self-center edit_delete"},[_c('button',{on:{"click":function($event){_vm.selectedItemToEdit = item;
              _vm.input = item.text;}}},[_vm._v(" Edit ")]),_c('span',{staticClass:"mx-1"},[_vm._v("|")]),_c('button',{on:{"click":function($event){_vm.itemList = _vm.itemList.filter((ele) => ele.id !== item.id)}}},[_vm._v(" Delete ")])])]),_c('v-divider',{attrs:{"color":_vm.color}})],1)}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }