var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c(_vm.$vuetify.breakpoint.xs
        ? 'MobileHeader'
        : _vm.$vuetify.breakpoint.sm
        ? 'TabletHeader'
        : 'DesktopHeader',{tag:"component",attrs:{"userName":"Megham"}}),_c('v-main',{staticClass:"page_background"},[(!_vm.$vuetify.breakpoint.xs && !_vm.$vuetify.breakpoint.sm)?_c('SubMenuDesktop'):_vm._e(),_c('router-view',{class:{
        desktop_sheet_small: _vm.$vuetify.breakpoint.md,
        desktop_sheet_large: _vm.$vuetify.breakpoint.lgAndUp,
        mobile_sheet_other: _vm.$vuetify.breakpoint.xs,
        mobile_sheet_bottom_large:
          _vm.$vuetify.breakpoint.xs && _vm.$route.meta.largeSpace,
        mobile_sheet_bottom_small:
          _vm.$vuetify.breakpoint.xs && !_vm.$route.meta.largeSpace,
        tablet_sheet: _vm.$vuetify.breakpoint.sm,
      },attrs:{"userName":"Megham"}})],1),(_vm.$vuetify.breakpoint.xs)?_c(_vm.$route.meta.footer,{tag:"component",attrs:{"path":_vm.$route.path}}):_c('PageFooter')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }