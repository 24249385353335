<template>
  <div>
    <v-card
      v-for="item in choices"
      :key="`${item.text}-bucket`"
      rounded="lg"
      max-height="170"
      @click="$emit('updateTab', item.id)"
      :class="{
        'text-truncate bucketListItem grow': true,
        'mb-2 pr-1': size === 'large',
        'mb-4 px-2 py-3': size === 'small',
        'grow-selected': item.id === selectedTab,
      }"
    >
      <CheckBox class="ma-2 mr-3" :color="color" :complete="item.complete" />
      {{ item.text }}
    </v-card>
  </div>
</template>

<script>
import CheckBox from "@/components/Common/Buttons/CheckBox.vue";

export default {
  name: "TabSelection",
  emits: ["updateTab"],
  props: ["selectedTab", "color", "size", "choices"],
  components: { CheckBox },
};
</script>

<style scoped>
.bucket_item_text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 36px;
  color: #000;
}
.grow {
  transition: all 0.2s ease-in-out;
}
.grow-selected {
  border: 1px dashed;
}
.grow:hover {
  transform: scale(1.1);
}
</style>
