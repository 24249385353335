<template>
  <v-app>
    <component
      :is="
        $vuetify.breakpoint.xs
          ? 'MobileHeader'
          : $vuetify.breakpoint.sm
          ? 'TabletHeader'
          : 'DesktopHeader'
      "
      userName="Megham"
    />
    <v-main class="page_background">
      <SubMenuDesktop
        v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm"
      />
      <router-view
        userName="Megham"
        :class="{
          desktop_sheet_small: $vuetify.breakpoint.md,
          desktop_sheet_large: $vuetify.breakpoint.lgAndUp,
          mobile_sheet_other: $vuetify.breakpoint.xs,
          mobile_sheet_bottom_large:
            $vuetify.breakpoint.xs && $route.meta.largeSpace,
          mobile_sheet_bottom_small:
            $vuetify.breakpoint.xs && !$route.meta.largeSpace,
          tablet_sheet: $vuetify.breakpoint.sm,
        }"
      ></router-view>
    </v-main>
    <component
      v-if="$vuetify.breakpoint.xs"
      :is="$route.meta.footer"
      :path="$route.path"
    />
    <PageFooter v-else />
  </v-app>
</template>

<script>
import DesktopHeader from "@/components/Headers/DesktopHeader.vue";
import MobileHeader from "@/components/Headers/MobileHeader.vue";
import TabletHeader from "@/components/Headers/TabletHeader.vue";
import PageFooter from "@/components/Footers/PageFooter.vue";
import SubMenuDesktop from "@/components/SubNavigation/SubMenuDesktop.vue";
import ArrangementFooter from "@/components/Footers/ArrangementFooter.vue";

export default {
  name: "App",
  components: {
    DesktopHeader,
    SubMenuDesktop,
    PageFooter,
    TabletHeader,
    ArrangementFooter,
    MobileHeader,
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins");
@import url("https://fonts.googleapis.com/css2?family=Castoro");
.page_background {
  background-color: #f9ebeb;
  background-image: url("./assets/Icons/left_cloud.svg"),
    url("./assets/Icons/right_cloud.svg");
  background-repeat: no-repeat, no-repeat;
  background-position: left -1% bottom 20%, right 1% top 25%;
  background-attachment: fixed, fixed;
}
.container {
  margin: 0 !important;
  padding: 0 !important;
}
.desktop_sheet_small {
  margin: 10px 80px 120px 380px;
}
.desktop_sheet_large {
  margin: 10px 140px 120px 380px;
}
.tablet_sheet {
  margin: 20px 76px 120px 80px;
}
.mobile_sheet_bottom_large {
  margin-bottom: 185px;
}
.mobile_sheet_bottom_small {
  margin-bottom: 70px;
}
.mobile_sheet_other {
  margin-left: 38px;
  margin-right: 38px;
  margin-top: 0;
}
</style>
