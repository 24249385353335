<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 43 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.46 0.61998C17.51 0.44998 15.53 0.409982 13.61 0.809982C6.79001 2.24998 2.24004 9.14996 1.20004 16.04C0.15004 22.93 1.83003 29.91 3.25003 36.74C3.58003 38.31 3.90003 39.92 4.75003 41.28C6.69003 44.41 10.73 45.38 14.38 45.84C17.87 46.28 21.39 46.5 24.91 46.49C30.71 46.49 37.29 45.42 40.51 40.61C42.37 37.83 42.71 34.32 42.83 30.98C43.01 25.89 42.83 20.79 42.26 15.73C41.95 12.95 41.47 10.05 39.78 7.83C38.14 5.68 35.61 4.49001 33.11 3.65001C30.42 2.74001 27.59 1.98001 24.81 1.40001C23.05 1.04001 21.26 0.76998 19.47 0.61998H19.46Z"
      :fill="colorScheme[outer_color]"
    />
    <path
      d="M32.4 34.77C32.3 34.67 32.17 34.62 32.04 34.62H31.02C31.08 34.62 30.86 24.1 30.83 22.73C30.79 20.77 30.74 18.77 30.04 16.95C28.84 13.82 25.63 11.56 22.28 11.5C18.93 11.44 15.63 13.56 14.31 16.65C13.58 18.35 13.43 20.23 13.29 22.07L12.69 29.73C12.56 31.35 12.76 32.99 12.76 34.63H11.75C11.75 34.63 11.72 34.63 11.7 34.63C11.57 34.63 11.44 34.7 11.35 34.8C11.26 34.9 11.21 35.03 11.22 35.17C11.22 35.3 11.29 35.43 11.39 35.52C11.49 35.61 11.62 35.66 11.76 35.65H32.06C32.2 35.65 32.33 35.6 32.42 35.5C32.52 35.4 32.57 35.27 32.57 35.14C32.57 35.01 32.52 34.8701 32.42 34.7801L32.4 34.77ZM18.44 21.09C18.53 20.99 18.66 20.93 18.79 20.92C18.79 20.92 18.82 20.92 18.84 20.92H24.93C25.07 20.92 25.2 20.97 25.29 21.07C25.39 21.17 25.44 21.3 25.44 21.43C25.44 21.56 25.39 21.7 25.29 21.79C25.19 21.89 25.06 21.94 24.93 21.94H18.84C18.71 21.94 18.57 21.9 18.47 21.81C18.37 21.72 18.31 21.59 18.3 21.46C18.29 21.33 18.34 21.19 18.43 21.09H18.44ZM25.29 28.39C25.19 28.49 25.06 28.54 24.93 28.54H18.84C18.71 28.54 18.57 28.5 18.47 28.41C18.37 28.32 18.31 28.19 18.3 28.06C18.3 27.93 18.34 27.79 18.43 27.69C18.52 27.59 18.65 27.53 18.78 27.52C18.8 27.52 18.81 27.52 18.83 27.52H24.92C25.06 27.52 25.19 27.57 25.28 27.67C25.38 27.77 25.43 27.9001 25.43 28.0301C25.43 28.1601 25.38 28.3 25.28 28.39H25.29ZM27.57 25.09C27.47 25.19 27.34 25.24 27.21 25.24H16.55C16.42 25.24 16.28 25.2 16.18 25.11C16.08 25.02 16.02 24.89 16.01 24.76C16.01 24.63 16.05 24.49 16.14 24.39C16.23 24.29 16.36 24.23 16.49 24.22C16.51 24.22 16.52 24.22 16.54 24.22H27.2C27.34 24.22 27.47 24.27 27.56 24.37C27.66 24.47 27.71 24.6 27.71 24.73C27.71 24.86 27.66 25 27.56 25.09H27.57Z"
      :fill="colorScheme[inner_color]"
    />
  </svg>
</template>

<script>
import { colorScheme } from "@/constants";

export default {
  name: "GraveIcon",
  props: {
    height: {
      type: Number,
      default: 47,
    },
    width: {
      type: Number,
      default: 43,
    },
    color: {
      type: String,
      default: "darkSalmon",
    },
    type: {
      type: String,
      default: "dark",
    },
  },
  setup(props) {
    let outer_color = props.color,
      inner_color = "white";
    if (props.type === "light") {
      inner_color = props.color;
      outer_color = "white";
    } else if (props.type === "bw") {
      outer_color = "grey_dark";
    }
    return { colorScheme, outer_color, inner_color };
  },
};
</script>
