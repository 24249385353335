<template>
  <router-link to="/">
    <svg
      width="136"
      height="32"
      viewBox="0 0 138 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M74.935 32.3679H70.421C70.2574 32.3677 70.098 32.3166 69.9648 32.2216C69.8317 32.1266 69.7314 31.9925 69.678 31.8379L66.056 21.3379C66.0332 21.2717 65.9903 21.2144 65.9333 21.1738C65.8763 21.1333 65.808 21.1117 65.738 21.1119H65.107C65.0181 21.1119 64.9327 21.1472 64.8698 21.21C64.8068 21.2728 64.7713 21.358 64.771 21.4469V31.5809C64.7711 31.6842 64.7509 31.7865 64.7114 31.882C64.672 31.9775 64.6141 32.0642 64.5411 32.1373C64.4681 32.2104 64.3814 32.2684 64.286 32.308C64.1906 32.3475 64.0883 32.3679 63.985 32.3679H59.668C59.5647 32.3679 59.4624 32.3475 59.367 32.308C59.2715 32.2684 59.1849 32.2104 59.1119 32.1373C59.0389 32.0642 58.981 31.9775 58.9415 31.882C58.9021 31.7865 58.8819 31.6842 58.882 31.5809V1.70791C58.882 1.49945 58.9648 1.29953 59.1122 1.15213C59.2596 1.00472 59.4595 0.921911 59.668 0.921911H66.668C67.8729 0.918755 69.0695 1.12168 70.206 1.52191C72.0368 2.29717 73.4943 3.75426 74.27 5.58491C74.669 6.64901 74.8722 7.77648 74.87 8.91291V13.1199C74.8722 14.2563 74.669 15.3838 74.27 16.4479C73.8865 17.355 73.3309 18.1791 72.634 18.8749C72.4095 19.099 72.1713 19.3091 71.921 19.5039C71.8567 19.554 71.8096 19.623 71.7863 19.7011C71.763 19.7793 71.7646 19.8627 71.791 19.9399L75.68 31.3269C75.7204 31.4454 75.7319 31.5718 75.7137 31.6956C75.6955 31.8194 75.6479 31.9371 75.5751 32.0389C75.5023 32.1407 75.4062 32.2237 75.2948 32.2809C75.1835 32.3381 75.0602 32.3679 74.935 32.3679ZM68.978 9.54591C68.978 7.12191 67.741 6.81191 66.664 6.81191H65.107C65.0181 6.81191 64.9327 6.84717 64.8698 6.90997C64.8068 6.97277 64.7713 7.05797 64.771 7.14691V14.8889C64.771 14.978 64.8064 15.0635 64.8694 15.1265C64.9324 15.1895 65.0179 15.2249 65.107 15.2249H66.664C67.741 15.2249 68.978 14.9139 68.978 12.4909V9.54591Z"
        :fill="colorScheme['red']"
      />
      <path
        d="M92.386 1.70812V6.02512C92.386 6.23358 92.3032 6.4335 92.1558 6.58091C92.0084 6.72831 91.8085 6.81112 91.6 6.81112H87.731C87.6268 6.81112 87.5268 6.85252 87.4531 6.92623C87.3794 6.99993 87.338 7.09989 87.338 7.20412V31.5811C87.338 31.7896 87.2552 31.9895 87.1078 32.1369C86.9604 32.2843 86.7605 32.3671 86.552 32.3671H82.234C82.0256 32.3669 81.8258 32.284 81.6785 32.1366C81.5312 31.9893 81.4483 31.7895 81.448 31.5811V7.20012C81.448 7.09589 81.4066 6.99593 81.3329 6.92223C81.2592 6.84853 81.1592 6.80712 81.055 6.80712H77.187C77.0837 6.80725 76.9814 6.78702 76.8859 6.74758C76.7904 6.70813 76.7037 6.65026 76.6306 6.57726C76.5575 6.50426 76.4995 6.41757 76.4599 6.32214C76.4204 6.22671 76.4 6.12442 76.4 6.02112V1.70812C76.4 1.60482 76.4204 1.50253 76.4599 1.4071C76.4995 1.31167 76.5575 1.22498 76.6306 1.15198C76.7037 1.07898 76.7904 1.0211 76.8859 0.981663C76.9814 0.942222 77.0837 0.921988 77.187 0.92212H91.6C91.8085 0.92212 92.0084 1.00493 92.1558 1.15233C92.3032 1.29974 92.386 1.49966 92.386 1.70812Z"
        :fill="colorScheme['red']"
      />
      <path
        d="M115.578 7.20612V13.2901C115.578 13.3943 115.619 13.4943 115.693 13.568C115.767 13.6417 115.867 13.6831 115.971 13.6831H121.1C121.308 13.6831 121.508 13.7659 121.656 13.9133C121.803 14.0607 121.886 14.2607 121.886 14.4691V18.7881C121.886 18.9966 121.803 19.1965 121.656 19.3439C121.508 19.4913 121.308 19.5741 121.1 19.5741H115.967C115.863 19.5741 115.763 19.6155 115.689 19.6892C115.615 19.7629 115.574 19.8629 115.574 19.9671V26.0831C115.574 26.1873 115.615 26.2873 115.689 26.361C115.763 26.4347 115.863 26.4761 115.967 26.4761H123.625C123.833 26.4761 124.033 26.5589 124.181 26.7063C124.328 26.8537 124.411 27.0537 124.411 27.2621V31.5811C124.411 31.7896 124.328 31.9895 124.181 32.1369C124.033 32.2843 123.833 32.3671 123.625 32.3671H110.473C110.265 32.3671 110.065 32.2843 109.917 32.1369C109.77 31.9895 109.687 31.7896 109.687 31.5811V1.70812C109.687 1.49966 109.77 1.29974 109.917 1.15233C110.065 1.00493 110.265 0.922119 110.473 0.922119H123.629C123.837 0.922119 124.037 1.00493 124.185 1.15233C124.332 1.29974 124.415 1.49966 124.415 1.70812V6.02712C124.415 6.23558 124.332 6.4355 124.185 6.58291C124.037 6.73031 123.837 6.81312 123.629 6.81312H115.971C115.867 6.81312 115.767 6.85452 115.693 6.92823C115.619 7.00193 115.578 7.10189 115.578 7.20612Z"
        :fill="colorScheme['red']"
      />
      <path
        d="M37.016 1.70006V31.5881C37.016 31.6914 36.9956 31.7937 36.9561 31.8891C36.9165 31.9845 36.8585 32.0712 36.7854 32.1442C36.7123 32.2172 36.6256 32.2751 36.5301 32.3145C36.4346 32.354 36.3323 32.3742 36.229 32.3741H31.929C31.7204 32.3741 31.5204 32.2913 31.3728 32.1439C31.2253 31.9965 31.1423 31.7966 31.142 31.5881V20.2001C31.1421 20.1246 31.1151 20.0515 31.0657 19.9944C31.0164 19.9372 30.9482 19.8997 30.8735 19.8888C30.7987 19.8779 30.7226 19.8942 30.659 19.9348C30.5953 19.9755 30.5485 20.0377 30.527 20.1101L28.276 27.6101C28.2274 27.772 28.1279 27.9139 27.9922 28.0148C27.8566 28.1156 27.692 28.1701 27.523 28.1701H23.439C23.2699 28.1704 23.1052 28.1161 22.9694 28.0152C22.8337 27.9142 22.7343 27.7721 22.686 27.6101L20.434 20.1101C20.4118 20.0382 20.3647 19.9767 20.3012 19.9366C20.2376 19.8964 20.1618 19.8803 20.0874 19.8912C20.0131 19.9021 19.945 19.9392 19.8956 19.9958C19.8462 20.0525 19.8187 20.1249 19.818 20.2001V31.5881C19.8181 31.6913 19.7979 31.7936 19.7584 31.889C19.719 31.9844 19.6611 32.0711 19.5881 32.1441C19.5151 32.2172 19.4284 32.275 19.3329 32.3145C19.2375 32.354 19.1352 32.3742 19.032 32.3741H14.732C14.5235 32.3741 14.3236 32.2913 14.1762 32.1438C14.0288 31.9964 13.946 31.7965 13.946 31.5881V1.70006C13.946 1.4916 14.0288 1.29168 14.1762 1.14428C14.3236 0.996873 14.5235 0.914063 14.732 0.914063H19.344C19.513 0.914021 19.6776 0.96848 19.8132 1.06936C19.9489 1.17024 20.0484 1.31216 20.097 1.47406L25.18 18.4171C25.1998 18.4812 25.2396 18.5372 25.2936 18.577C25.3476 18.6169 25.4129 18.6383 25.48 18.6383C25.5471 18.6383 25.6124 18.6169 25.6664 18.577C25.7204 18.5372 25.7602 18.4812 25.78 18.4171L30.865 1.47506C30.9137 1.31324 31.0133 1.17141 31.1489 1.07056C31.2845 0.969701 31.449 0.915181 31.618 0.915063H36.229C36.3323 0.914931 36.4346 0.935165 36.5301 0.974606C36.6256 1.01405 36.7123 1.07192 36.7854 1.14492C36.8585 1.21792 36.9165 1.30462 36.9561 1.40004C36.9956 1.49547 37.016 1.59776 37.016 1.70106"
        :fill="colorScheme['red']"
      />
      <path
        d="M56.179 9.3V23.968C56.1828 25.2656 55.9654 26.5544 55.536 27.779C54.9111 29.2592 53.8635 30.5221 52.5242 31.4097C51.185 32.2973 49.6137 32.7702 48.007 32.769H47.588C45.9817 32.7683 44.4112 32.2948 43.0723 31.4074C41.7334 30.52 40.6854 29.2581 40.059 27.779C39.6296 26.5544 39.4122 25.2656 39.416 23.968V9.3C39.4122 8.00236 39.6296 6.71356 40.059 5.489C40.684 4.00901 41.7317 2.74627 43.071 1.85885C44.4102 0.971424 45.9814 0.498753 47.588 0.500002H48.007C49.6134 0.50014 51.1841 0.973483 52.5231 1.86093C53.8621 2.74838 54.91 4.0106 55.536 5.49C55.9653 6.71424 56.1827 8.00269 56.179 9.3ZM50.312 9.509C50.3693 8.64526 50.1169 7.78937 49.6 7.095C49.1153 6.62727 48.4681 6.36588 47.7945 6.36588C47.1209 6.36588 46.4737 6.62727 45.989 7.095C45.4729 7.79004 45.2216 8.6463 45.28 9.51V23.759C45.2215 24.6224 45.4729 25.4784 45.989 26.173C46.4737 26.6407 47.1209 26.9021 47.7945 26.9021C48.4681 26.9021 49.1153 26.6407 49.6 26.173C50.1161 25.4783 50.3675 24.6224 50.309 23.759L50.312 9.509Z"
        :fill="colorScheme['red']"
      />
      <path
        d="M108.033 27.2611V31.5811C108.033 31.7896 107.95 31.9895 107.803 32.1369C107.655 32.2843 107.455 32.3671 107.247 32.3671H94.93C94.7215 32.3671 94.5216 32.2843 94.3742 32.1369C94.2268 31.9895 94.144 31.7896 94.144 31.5811V1.70812C94.144 1.49966 94.2268 1.29974 94.3742 1.15233C94.5216 1.00493 94.7215 0.922119 94.93 0.922119H99.25C99.4585 0.922119 99.6586 1.0049 99.8061 1.15227C99.9537 1.29965 100.037 1.49957 100.037 1.70812V26.0821C100.037 26.1863 100.078 26.2863 100.152 26.36C100.226 26.4337 100.326 26.4751 100.43 26.4751H107.247C107.455 26.4751 107.655 26.5579 107.803 26.7053C107.95 26.8527 108.033 27.0527 108.033 27.2611Z"
        :fill="colorScheme['red']"
      />
      <path
        d="M135.843 19.6541H54.33C53.9507 19.6541 53.587 19.5034 53.3188 19.2353C53.0507 18.9671 52.9 18.6034 52.9 18.2241V14.7801C52.9 14.4008 53.0507 14.0371 53.3188 13.7689C53.587 13.5008 53.9507 13.3501 54.33 13.3501H135.843C136.031 13.3501 136.217 13.3871 136.39 13.459C136.564 13.5308 136.721 13.6362 136.854 13.769C136.987 13.9018 137.092 14.0594 137.163 14.2329C137.235 14.4064 137.272 14.5924 137.272 14.7801V18.2241C137.272 18.4118 137.235 18.5978 137.163 18.7713C137.092 18.9447 136.987 19.1024 136.854 19.2352C136.721 19.368 136.564 19.4733 136.39 19.5452C136.217 19.6171 136.031 19.6541 135.843 19.6541Z"
        :fill="colorScheme['orange']"
      />
      <path
        d="M51.843 19.6541H2.32999C1.95073 19.6541 1.58701 19.5034 1.31883 19.2353C1.05065 18.9671 0.899994 18.6034 0.899994 18.2241V14.7801C0.899994 14.4008 1.05065 14.0371 1.31883 13.7689C1.58701 13.5008 1.95073 13.3501 2.32999 13.3501H51.843C52.0307 13.3501 52.2166 13.3871 52.3901 13.459C52.5635 13.5308 52.7211 13.6362 52.8538 13.769C52.9865 13.9018 53.0918 14.0594 53.1635 14.2329C53.2353 14.4064 53.2721 14.5924 53.272 14.7801V18.2241C53.2721 18.4118 53.2353 18.5978 53.1635 18.7713C53.0918 18.9447 52.9865 19.1024 52.8538 19.2352C52.7211 19.368 52.5635 19.4733 52.3901 19.5452C52.2166 19.6171 52.0307 19.6541 51.843 19.6541Z"
        :fill="colorScheme['orange']"
      />
      <path
        d="M56.179 11.95H50.312V21.71H56.179V11.95Z"
        :fill="colorScheme['red']"
      />
    </svg>
  </router-link>
</template>

<script>
import { colorScheme } from "@/constants";

export default {
  name: "FullLogo",
  setup() {
    return { colorScheme };
  },
};
</script>
