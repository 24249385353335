<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.78 30.38C24.0643 30.38 30.78 23.6643 30.78 15.38C30.78 7.09573 24.0643 0.380005 15.78 0.380005C7.49576 0.380005 0.780029 7.09573 0.780029 15.38C0.780029 23.6643 7.49576 30.38 15.78 30.38Z"
      :fill="colorScheme[color]"
    />
    <path
      d="M20.57 15.45H18V24.87H14.11V15.45H12.26V12.14H14.11V10C14.11 8.47 14.84 6.07001 18.04 6.07001H20.92V9.28998H18.83C18.49 9.28998 18 9.46 18 10.19V12.14H20.91L20.57 15.45Z"
      :fill="colorScheme['white']"
    />
  </svg>
</template>

<script>
import { colorScheme } from "@/constants";

export default {
  name: "FacebookIcon",
  props: {
    height: {
      type: Number,
      default: 31,
    },
    width: {
      type: Number,
      default: 31,
    },
    color: {
      type: String,
      default: "red",
    },
  },
  setup() {
    return { colorScheme };
  },
};
</script>
