<template>
  <v-dialog
    :value="showModal"
    transition="dialog-top-transition"
    :width="width"
    @click:outside="$emit('update:showModal', false)"
  >
    <v-card height="100%" width="100%">
      <v-toolbar
        v-if="showToolBar"
        :style="`background-color: ${colorScheme[toolbarColor]}`"
        :elevation="toolbarElevation"
      >
        <v-toolbar-title :class="`font-weight-bold ${toolbarTextColor}--text`">
          {{ toolbarText }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text class="mt-4" v-if="content.length > 0"
        ><span>{{ content }}</span></v-card-text
      >
      <slot name="content"></slot>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :color="btnColor"
          :class="`${toolbarTextColor}--text`"
          @click="
            $emit('button-click');
            $emit('update:showModal', false);
          "
        >
          {{ buttonText }}
          <v-icon small :color="toolbarTextColor" class="ml-2">{{
            buttonIcon
          }}</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { colorScheme } from "@/constants";

export default {
  name: "BaseModal",
  props: {
    showModal: {
      type: Boolean,
      default: true,
    },
    toolbarTextColor: {
      type: String,
      default: "black",
    },
    toolbarColor: {
      type: String,
      default: "grey_medium",
    },
    btnColor: {
      type: String,
      default: "primary",
    },
    showToolBar: {
      type: Boolean,
      default: true,
    },
    toolbarText: {
      type: String,
      default: "Privacy Policy",
    },
    width: {
      type: String,
      default: "100%",
    },
    buttonIcon: {
      type: String,
      default: "mdi-share-variant",
    },
    buttonText: {
      type: String,
      default: "i accept",
    },
    content: {
      type: String,
      default: "",
    },
    toolbarElevation: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    return {
      colorScheme,
    };
  },
};
</script>

<style scoped>
span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #a3a3a3;
  line-height: 20px;
}
</style>
