<template>
  <div class="pb-12">
    <ProgressDots
      class="mb-4"
      :totalSteps="choices.length"
      :completedSteps="selectedTab"
      :color="color"
    />
    <ProgressCard
      :color="color"
      :icon="icon"
      :title="title"
      :completedSteps="completedSteps"
      state="active"
      :totalSteps="totalSteps"
      :class="{
        'mr-7 mb-6': !$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs,
        'mr-9': $vuetify.breakpoint.sm,
        'pa-2 my-5': true,
      }"
    />
    <v-card flat rounded="lg" class="pa-7 pt-8">
      <div class="main_text">
        {{ choices.find((ele) => ele.id === selectedTab).text }}
      </div>
      <span class="sub_text">
        <GuideText
          :textList="choices.find((ele) => ele.id === selectedTab).guideText"
        />
      </span>
    </v-card>
    <v-card
      class="my-3 py-6"
      flat
      rounded="lg"
      :min-height="$vuetify.breakpoint.smAndUp ? 570 : 450"
    >
      <div class="main_text" v-if="$vuetify.breakpoint.smAndUp">
        {{ choices.find((ele) => ele.id === selectedTab).text }}
      </div>
      <div
        :class="{
          'sub_text sub_text_margin': true,
          'mt-2 mb-5': !$vuetify.breakpoint.smAndUp,
        }"
      >
        {{ choices.find((ele) => ele.id === selectedTab).question }}
      </div>
      <component
        :is="choices.find((ele) => ele.id === selectedTab).component"
        :color="color"
        @completed="
          choices.find((ele) => ele.id === selectedTab).complete = true
        "
        @pending="
          choices.find((ele) => ele.id === selectedTab).complete = false
        "
        :class="choices.find((ele) => ele.id === selectedTab).classes"
      />
    </v-card>

    <v-fade-transition>
      <v-btn
        class="mid-left ml-2"
        fab
        v-show="selectedTab !== 1"
        small
        :color="color"
        @click="
          selectedTab =
            selectedTab === 1
              ? choices.length
              : (selectedTab - 1) % (choices.length + 1)
        "
      >
        <v-icon color="white">mdi-chevron-left</v-icon>
      </v-btn>
    </v-fade-transition>
    <v-fade-transition>
      <v-btn
        class="mid-right"
        v-show="selectedTab !== choices.length"
        fab
        small
        :color="color"
        @click="
          selectedTab =
            selectedTab === choices.length
              ? 1
              : (selectedTab + 1) % (choices.length + 1)
        "
      >
        <v-icon color="white">mdi-chevron-right</v-icon>
      </v-btn>
    </v-fade-transition>
  </div>
</template>

<script>
import { ref } from "vue";
import ProgressDots from "@/components/Common/ProgressDots.vue";
import ProgressCard from "@/components/Common/ProgressCard.vue";
import DieFulfilled from "@/Views/Arrangements/DieFulfilled.vue";
import BucketList from "@/Views/Arrangements/BucketList.vue";
import RememberMe from "@/Views/Arrangements/RememberMe.vue";
import MyEpitaph from "@/Views/Arrangements/MyEpitaph.vue";
import GuideText from "@/Views/Arrangements/GuideText.vue";
import MyStory from "@/Views/Arrangements/MyStory.vue";
import GraveMessages from "@/Views/Arrangements/GraveMessages.vue";
import FarewellToneTheme from "@/Views/Arrangements/FarewellToneTheme.vue";
import FarewellPriorities from "@/Views/Arrangements/FarewellPriorities.vue";
import FarewellAnythingElse from "@/Views/Arrangements/FarewellAnythingElse.vue";
import FarewellMedia from "@/Views/Arrangements/FarewellMedia.vue";
import FarewellMusic from "@/Views/Arrangements/FarewellMusic.vue";
import FarewellAfterparty from "@/Views/Arrangements/FarewellAfterparty.vue";
import FarewellSpeakers from "@/Views/Arrangements/FarewellSpeakers.vue";
import SensitiveInstructions from "@/Views/Arrangements/SensitiveInstructions.vue";
import SensitiveWill from "@/Views/Arrangements/SensitiveWill.vue";
import SensitiveDigitalfootprint from "@/Views/Arrangements/SensitiveDigitalfootprint.vue";

export default {
  name: "LifeArrangementsSmall",
  components: {
    DieFulfilled,
    ProgressDots,
    RememberMe,
    MyEpitaph,
    GraveMessages,
    FarewellMedia,
    FarewellAfterparty,
    SensitiveInstructions,
    FarewellMusic,
    SensitiveDigitalfootprint,
    BucketList,
    MyStory,
    FarewellToneTheme,
    FarewellPriorities,
    FarewellAnythingElse,
    FarewellSpeakers,
    ProgressCard,
    GuideText,
    SensitiveWill,
  },
  props: ["icon", "color", "totalSteps", "icon_light", "title", "choices"],
  setup(props) {
    const completedSteps = props.choices.filter((tab) => tab.complete).length;
    const selectedTab = ref(1);
    return {
      completedSteps,
      selectedTab,
    };
  },
};
</script>

<style scoped>
.main_text {
  font-family: "Castoro";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 42px;
  color: #231f20;
}
.sub_text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;
  color: #000;
}
.bucket_item_text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 36px;
  color: #000;
}
.head_text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #000;
}
.edit_delete {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 22px;
  color: #919396;
}
.item_name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 22px;
  color: black;
}
.text_style {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}
.main_text {
  font-family: "Castoro";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #231f20;
  margin: 0 0 21px 32px;
}
.sub_text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #000;
}
.sub_text_margin {
  margin: 0 47px 0 32px;
}
.relative-container {
  position: relative;
}
.mid-left {
  position: fixed;
  top: 50vh;
  left: 10px;
  transform: translateY(-90%);
  color: #919396;
}
.mid-right {
  position: fixed;
  top: 50vh;
  right: 10px;
  transform: translateY(-90%);
}
</style>
