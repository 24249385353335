<template>
  <div>
    <TextArea :value.sync="input" :disabled="!editMode" />
    <v-row no-gutters class="mt-3">
      <v-spacer />
      <v-fade-transition>
        <BaseButton
          v-show="editMode"
          @click="
            editMode = false;
            input = value;
          "
          color="grey_dark"
          textColor="grey_dark"
          text="Cancel"
          :outlined="true"
          class="mr-2"
        />
      </v-fade-transition>
      <BaseButton
        v-if="editMode"
        @click="
          editMode = false;
          input.length > 0 && $emit('completed');
        "
        :color="color"
        textColor="white"
        text="Save"
      />
      <BaseButton
        v-else
        @click="editMode = true"
        :color="color"
        textColor="white"
        text="Edit"
        class="ml-2"
      />
    </v-row>
  </div>
</template>

<script>
import { ref } from "vue";
import BaseButton from "@/components/Common/Buttons/BaseButton.vue";
import TextArea from "@/components/Common/TextArea.vue";

export default {
  name: "RememberMe",
  components: {
    BaseButton,
    TextArea,
  },
  props: {
    color: {
      type: String,
      required: false,
      default: "orange",
    },
    value: {
      type: String,
      required: false,
      default: "I want to be remembered for my work!",
    },
  },
  setup(props) {
    const input = ref(props.value);
    const editMode = ref(false);
    return {
      input,
      editMode,
    };
  },
};
</script>
