<template>
  <LifeArrangementsSmall
    v-if="$vuetify.breakpoint.xs"
    :icon="data.icon"
    :choices="MyArrangementsChoices[route.path.split('/')[2]]"
    :color="data.color"
    :totalSteps="data.totalSteps"
    :title="data.title"
  />
  <LifeArrangementsLarge
    v-else
    :icon="data.icon"
    :choices="MyArrangementsChoices[route.path.split('/')[2]]"
    :color="data.color"
    :title="data.title"
    class="mt-7"
  />
</template>

<script>
import { MyArrangementsChoices } from "@/constants";
import { reactive } from "vue";
import { arrangementDetails } from "@/constants";
import LifeArrangementsLarge from "@/Views/Arrangements/LifeArrangementsLarge.vue";
import LifeArrangementsSmall from "@/Views/Arrangements/LifeArrangementsSmall.vue";
import { useRoute } from "vue2-helpers/vue-router";

export default {
  name: "LifeArrangements",
  props: ["userName"],
  components: {
    LifeArrangementsSmall,
    LifeArrangementsLarge,
  },
  setup() {
    const route = useRoute();
    const data = reactive(
      arrangementDetails.find((ele) => ele.id === route.params.section)
    );
    return {
      data,
      MyArrangementsChoices,
      route,
    };
  },
};
</script>
